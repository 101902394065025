import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_OBJECTS_STATE, loading, putObjectsState } from '../../store/Reports/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_OBJECTS_STATE, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const objects_state = yield call(fetchObjectsState)
        if (objects_state.error && objects_state.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_OBJECTS_STATE}.`, objects_state],
                text: "Статусы объектов не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putObjectsState({objects_state:[]}))
        } else {
            yield put(putObjectsState({ objects_state: objects_state.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_OBJECTS_STATE}.`, e],
            text: "Состояния объектов не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putObjectsState({objects_state:[]}))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchObjectsState() {
    return axios.get("/msbox/select?hidden=NULL")
        .then(response => response.data)
}
