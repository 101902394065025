import React, { useMemo, useState, useEffect, useCallback, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import { loadAllPublicSongs } from '../../../../../../store/PublicPlaylists/actions';

import List from '../../../../../../components/DraggableList'
import SongItem from '../../AllSongs/Songs'

import SvgArrowDownLight from '../../../../../../components/SVG/ArrowDownLight'
import ListNew from '../../../../../../components/DraggableList/ListNew';

export const AllPublicSongs = React.forwardRef(({
    songs,
    audio,
    trackPlaying,
    isPlaying,

    songRenderItem,

    hPlay,
    hPause,
    hStop,
    hRewind,
    toggleSelectedP,

    loadMorePublicTraks: loadMorePublicTraksInner
}, ref) => {

    const {
        list,
        loading,
        selected
    } = useSelector(state => ({
        list: state.public_playlists.allPublicTracks,
        loading: state.public_playlists.loading_all,
        selected: state.public_playlists.selected_song,
    }))

    const getSelectedTracks = () => {

        return [...list.values()].filter(song => selected.indexOf(song.id) !== -1)

    }

    const getTrackByIndex = (index) => {

        return [...list.values()][index]

    }

    useImperativeHandle(ref, () => ({
        getSelectedTracks,
        getTrackByIndex
    }));


    const loadMorePublicTraks = () => loadMorePublicTraksInner(true);




    const items = useMemo(() => {
        return [...list.values()].map((song, index) => ({
            id: song.id,
            name: song.name,
            element: < SongItem
                key={song.id}
                id={song.id}
                name={song.name}
                variant={(index % 2 == 0) ? "gray" : ""}
                songs={songs}
                selected={selected}
                tags={song.tags}
                turn={song.turn}
                cliptime={song.cliptime}
                tempo={song.tempo}
                songauthor_name={song.songauthor_name}
                hide_song_author_name={true}

                player_name={song.player_name}
                album_name={song.album_name}
                genre_name={song.genre_name}
                year={song.year}

                filename={song.filename}

                audio={audio}
                trackPlaying={trackPlaying}
                isPlaying={isPlaying}
                play={hPlay}
                // pause={hPause}
                pause={() => console.log('PAUSE !!! ?>')}
                stop={hStop}
                rewind={hRewind}
            />
        }))
    }, [list])

    const getItems = () => {
        return [...list.values()].map((song, index) => songRenderItem(song, index))
    }


    return (
        <ListNew
            type="simple"
            // offset={offset}
            // loading={loading}
            offsetAdd={50}
            // loadButton={!loading && <div className="ScrollContainer__more">
            //     <div onClick={loadMorePublicTraks}>
            //         Загрузить ещё
            //         <SvgArrowDownLight title="Загрузить ещё" variant="dark" size="xs" />
            //     </div>
            // </div>}
            loadMore={loadMorePublicTraks}
            scroll={{
                on: true,
                data: {
                    variant: "white",
                    size: "sm",
                }
            }}
            onClick={toggleSelectedP}
            content={{ items: getItems(), variant: "none" }}
            droppableId={`1`}
            // dnd_disabled
        />
    )
})

export default AllPublicSongs;
// Line 37:24:  'SongItem' is not defined              react/jsx-no-undef
// Line 42:24:  'songs' is not defined                 no-undef
// Line 43:27:  'selected_public_song' is not defined  no-undef
// Line 58:24:  'audio' is not defined                 no-undef
// Line 59:31:  'trackPlaying' is not defined          no-undef
// Line 60:28:  'isPlaying' is not defined             no-undef
// Line 61:23:  'hPlay' is not defined                 no-undef
// Line 62:24:  'hPause' is not defined                no-undef
// Line 63:23:  'hStop' is not defined                 no-undef
// Line 64:25:  'hRewind' is not defined               no-undef
// Line 75:41:  'all_public_tracks' is not defined     no-undef
// Line 78:22:  'SvgArrowDownLight' is not defined     react/jsx-no-undef
// Line 88:22:  'toggleSelectedP' is not defined       no-undef