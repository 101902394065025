import React, { useEffect, useRef, useState } from 'react';
import InputNumber from '../InputNumber';
import { Form, FormCheck } from 'react-bootstrap';
import Switch from '../Switch';
import block from '../../Settings/sets/AdvertTracks/items/block';

const DurationInput = ({ value, onChange, ever, onEverChange }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleChange = ({ value }) => {
        if (typeof onChange === 'function') {
            onChange(value);  // Простой вызов, без изменения значений
        }
    };

    const inputValidate = (value) => {
        return value >= 0 && value <= 9999;
    };


    const handleCheckboxChange = (event) => {
        event.stopPropagation()
        if (value === 999 || value === '999') {
            onChange(30);  // Если значение 999, ставим 30
        } else {
            onChange(999); // Иначе ставим 999
        }
        // toggleNoEnd();  // Включаем/выключаем "Постоянно"
    };

    return (
        <div className="FormDatepickerDurationInput" style={{ width: '130px' }}>

            <div className="FormDatepickerDurationInput__title" style={{ visibility: ever ? 'hidden' : 'visible' }}>
                Кол-во дней
            </div>
            <div className="FormDatepickerDurationInput__input"  style={{ visibility: ever ? 'hidden' : 'visible' }}>
                <InputNumber
                    ref={inputRef}
                    onChange={handleChange}
                    validate={inputValidate}
                    attrs={{
                        value: String(value) || '',
                    }}
                />
            </div>

            <div style={{ justifyContent: 'center', alignItems: 'center' }}>

                <label style={{ marginTop: 22, textAlign: 'center' }} class="SettingsFeatures__label">{"Транслировать"}<br />{"постоянно"}</label>

                <div style={{ marginLeft: 22 }}>
                    <Switch
                        label="ds"
                        value={ever}
                        onChange={onEverChange}
                        name="mute"
                    />
                </div>
            </div>

        </div>
    );
};

export default DurationInput;
