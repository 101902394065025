export const LOAD_OBJECTS_GROUP    = "ObjectsGroup/LOAD_OBJECTS_GROUP"
export const PUT_OBJECTS_GROUP     = "ObjectsGroup/PUT_OBJECTS_GROUP"
export const PUT_GROUPS_LIST       = "ObjectsGroup/PUT_GROUPS_LIST"

export const CREATE_OBJECTS_GROUP  = "ObjectsGroup/CREATE_OBJECTS_GROUP"
export const DELETE_OBJECTS_GROUP  = "ObjectsGroup/DELETE_OBJECTS_GROUP"
export const SAVE_OBJECTS_GROUP    = "ObjectsGroup/SAVE_OBJECTS_GROUP"

export const TOGGLE_SELECTED_GROUP = "ObjectsGroup/TOGGLE_SELECTED_GROUP"
export const LOADING               = "ObjectsGroup/LOADING"

export const loadObjectsGroup = payload => ({
    type: LOAD_OBJECTS_GROUP,
    payload
})

export const putObjectsGroup = ({ groups, groupsAssoc }) => ({
    type: PUT_OBJECTS_GROUP,
    payload: {
        groups,
        groupsAssoc,
    }
})

export const putGroupsList = groupsList => ({
    type: PUT_GROUPS_LIST,
    payload: {
        groupsList
    }
})

export const createObjectsGroup = ({ name, copy_id, copy_clip, copy_music }) => ({
    type: CREATE_OBJECTS_GROUP,
    payload: {
        name, copy_id, copy_clip, copy_music
    }
})

export const deleteObjectsGroup = ({ id }) => ({
    type: DELETE_OBJECTS_GROUP,
    payload: {
        id
    }
})

export const saveObjectsGroup = ({ id, title }) => ({
    type: SAVE_OBJECTS_GROUP,
    payload: {
        id,
        title,
    }
})

export const toggleSelectedGroup = ({ id, selected, objectsIds }) => ({
    type: TOGGLE_SELECTED_GROUP,
    payload: {
        id,
        selected,
        objectsIds,
    }
})

export const loading = mode => ({
    type: LOADING,
    payload: {
        mode
    }
})