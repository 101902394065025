import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { UPDATE_CLIP, loadClips, DELETE_CLIPS, putClipsAll } from '../../../store/Settings/actions/components/Clips'
import { loadTracks } from '../../../store/Tracks/actions'
import { loadIndentObjects } from '../../../store/Objects/actions'
import { deleteMark } from '../../../store/Marks/actions'
import { showAlertSuccess, showAlertError, showAlertInfo } from '../../../store/Alert/actions'
import axios from 'axios'
import { closeModal } from "../../../store/Modal/actions";
import { parseClipId } from "../../../utilities/parseClipId";
import { SAVE_SETTINGS } from "../../../store/Settings/actions/sets/Advert/Tracks";
import { loading } from '../../../store/App/actions'

export default function* () {
    yield takeEvery(UPDATE_CLIP, worker)
}

function* worker({ payload }) {
    console.log({ payload });
    yield put(loading(true))

    try {
        let selectedScheduleArray = payload.selectedScheduleArray
        let clipData = payload.clipData
        let oldClipData = payload.oldClipData
        let action = payload.action
        let onSuccess = payload.onSuccess
        const selectedObjects = [...new Set(selectedScheduleArray.map(i => i.ms_id))];
        let selectedTracks = [];
        selectedScheduleArray.forEach(i => selectedTracks.push(...i.indent_ids));
        selectedTracks = [...new Set(selectedTracks)];
        clipData = validateClipData(clipData)

        const ms_id = {}

        selectedScheduleArray.forEach((schedule) => {

            ms_id[schedule.ms_id] = schedule.ms_id
        });

        const responses = yield all(selectedScheduleArray.map(schedule => call(fetchUpdate, schedule, clipData, oldClipData, action, selectedTracks)));
        let errorResponses = responses.filter(resp => resp.error)
        if (errorResponses.length) {
            for (const errorR of errorResponses) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${UPDATE_CLIP}.`, clipData.id],
                    text: `Не все расписания были обновлены. ${errorR.error}`
                }));
            }
            return;
        } else {
            let warningResponses = responses.filter(resp => resp.warning)
            for (const wR of warningResponses) {
                yield put(showAlertSuccess({
                    text: wR.warning
                }))
            }
            if (!warningResponses.length) {
                yield put(showAlertSuccess({
                    text: `Настройки успешно сохранены.`
                }))
            }
            yield put(closeModal("settingsClipEdit"))
            yield put(putClipsAll({
                clips: {}
            }))
            //console.log(selectedTracks)
            yield put(loadIndentObjects())
            yield all(
                selectedTracks.map(indentId =>
                    put(loadClips({
                        indentId,
                        objectIds: selectedObjects,
                        required: true,
                    }))
                )
            )
            onSuccess()
            try {
                if (Object.keys(ms_id).length >= 1000)
                    yield put(showAlertInfo({ text: "Загружается большое количество объектов. Это займет некоторое время" }))
            }
            catch (e) {

            }

        }

    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_CLIP}.`, e],
            text: "Не все расписания были удалены. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchUpdate(schedule, clipData, oldClipData, action, selectedTracks) {
    let formData = new FormData();
    const allowed = action === 'replace' ? ['block', 'fromdate', 'fromtime', 'daymask', 'todate', 'totime', 'channel', 'ms_id', 'indent_id', 'steptime']
        : ['fromdate', 'todate'];
    for (let name in clipData) {
        if (clipData[name] && clipData[name] !== "" && allowed.indexOf(name) !== -1) {
            if (Array.isArray(clipData[name])) {
                clipData[name].forEach((item) => formData.append(`${name}[]`, item))
            } else {
                formData.set(name, clipData[name])
            }
        }
    }

    let params = {
        mark: schedule.mark,
        ms_id: schedule.ms_id,
        indent_id: schedule.indent_ids
    }
    if (!params.mark) {
        params.fromdate = schedule.fromdate;
        params.todate = schedule.todate;
        params.channel = schedule.channel;
    }
    //если много объектов, то марк не поможет (или делать массив марков, но бывают записи без марков, а частичное обновление нам не нужно)
    if (selectedTracks.length > 1) {
        delete params.mark;
        params.fromdate = schedule.fromdate;
        params.todate = schedule.todate;
        params.channel = schedule.channel;
    }
    if (formData.has('ms_id')) {
        formData.set('ms_id', schedule.ms_id)
    }
    if (formData.has('indent_id')) {
        formData.set('indent_id', schedule.indent_id)
    }
    if (clipData?.ever)
        formData.set('ever', clipData.ever)

    //console.log(clipData, selectedIds, selectedTracks, selectedObjects)
    return axios(
        {
            url: `/timeline/clip/${action}`,
            method: "POST",
            params,
            data: formData
        }
    ).then(response => response.data);
}

function validateClipData(clipData) {
    if (Array.isArray(clipData.indentIds)) clipData.indentId = clipData.indentIds.join(",")
    if (Array.isArray(clipData.indentId)) clipData.indentId = clipData.indentId.join(",")
    if (Array.isArray(clipData.objectIds)) clipData.objectId = clipData.objectIds.join(",")
    if (Array.isArray(clipData.objectId)) clipData.objectId = clipData.objectId.join(",")

    if (clipData.indentId) clipData.indent_id = clipData.indentId.split(',')
    if (clipData.objectId) clipData.ms_id = clipData.objectId

    delete clipData.indentIds
    delete clipData.indentId
    delete clipData.objectIds
    delete clipData.objectId
    delete clipData.__inday

    return clipData
}