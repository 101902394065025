import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/SpaceContent/Place'
import Empty from '../../../../components/SpaceContent/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import PLaylistSongs from "./PlaylistSongs"
import VideolistVideos from "./VideolistVideos"
import { loadAvmapBindings } from '../../../../store/ContentPlaylists/actions'
import List from '../../../../components/List'


const Where = ({
    selectedPlaylists,
    currentPlaylist,
    selectedVideolists,
    currentVideolist,
    collapsibleName,
    loadAvmapBindings,
    avmap,
    allPlaylists,
    allVideolists,
    loading_avmap
}) => {
    useEffect(() => {
        //if (avmap.length === 0) {
        loadAvmapBindings()
        //}
    }, [])
    let videolist_name = ""
    if (allVideolists.length > 0 && avmap.length > 0 && allPlaylists.length > 0) {
        //console.log(avmap)
        let avmap_obj = avmap.filter(av => av.playlist_id === selectedPlaylists[0])[0]
        if (avmap_obj) {
            let videolist_obj = allVideolists.filter(list => list.id === avmap_obj.videolist_id)[0]
            videolist_name = videolist_obj?.name

        }
        //console.log(videolist_name)
    }
    if (selectedPlaylists.length > 0) selectedVideolists = []
    if (selectedVideolists.length > 0) selectedPlaylists = []
    return (
        <>
            {collapsibleName === "music"
                ?
                <Place
                    number={2}
                    className="ObjectsWhere"
                // style={ { width: "67%" } }
                >
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                        <div>
                            <span>ТРЕКИ ПЛЕЙЛИСТА</span>
                            {currentPlaylist !== undefined && <h3>{`"${currentPlaylist.name}"`}
                                 {/* {`(${currentPlaylist.totalcount} эл, ${currentPlaylist.totaltime || 0} )`} */}
                                 </h3>}
                                                

                        </div>
                        {videolist_name !== "" &&
                            <div style={{ margin: "0 40px" }}>
                                <span>С ЭТИМ ПЛЕЙЛИСТОМ СВЯЗАН ВИДЕО СПИСОК</span>
                                <h3>{`"${videolist_name}"`}</h3>
                            </div>
                        }

                    </div>
                    {(selectedPlaylists?.length > 0)
                        ? <SlideMenu
                            activeItem={1}
                            items={[
                                {
                                    title: "Настройки Плейлиста",
                                    content: <PLaylistSongs key={6} placeNumber={1} />
                                }
                            ]}
                        />

                        :
                        <>
                            <Empty>
                                Выберите плейлист
                            </Empty>
                            <SlideMenu
                                activeItem={1}
                                items={[
                                    {
                                        title: "Настройки Плейлиста",
                                        content: <PLaylistSongs key={6} placeNumber={1} />
                                    }
                                ]}
                            />
                        </>
                    }
                </Place>
                :
                <Place
                    number={2}
                    className="ObjectsWhere"
                // style={ { width: "67%" } }
                >
                    ВИДЕО-СПИСОК
                    {currentVideolist !== undefined ? <h3>{`"${currentVideolist.name}"`}
                         {/* {`(${currentVideolist.totalcount} эл, ${currentVideolist.totaltime  || 0} )`} */}
                         </h3> :
                        <Empty>Выберите видео-список</Empty>}

                    <SlideMenu
                        activeItem={1}
                        items={[
                            {
                                title: "Настройки видеолиста",
                                content: <VideolistVideos placeNumber={2} active />
                            }
                        ]}
                    />



                </Place>
            }
        </>)
}

const stateToProps = state => {
    const currentPlaylist = state.playlists.allPlaylists ? state.playlists.allPlaylists.filter(cur => cur.id === state.playlists.selected[0])[0] : undefined;
    // const currentVideolist = state.videolists.allVideolists && state.videolists.allVideolists.length ? state.videolists.allVideolists.find(cur => cur.id === state.videolists.selected[0]) : undefined;
    const currentVideolist = state.videolists.selected ? state.videolists_all.allVideolists.find(cur => cur.id === state.videolists.selected[0]) : undefined
    
    return {
        selectedPlaylists: [...state.playlists.selected],
        selectedVideolists: [...state.videolists.selected],
        allPlaylists: [...state.playlists.allPlaylists],
        allVideolists: [...state.videolists.allVideolists],
        currentPlaylist: currentPlaylist,
        currentVideolist: currentVideolist,
        collapsibleName: state.collapsible.collapsibleName,
        avmap: state.playlists.avmap_bindings,
        loading_avmap: state.playlists.loading_avmap
    }
};
const actionsToProps = {
    loadAvmapBindings
}
export default connect(stateToProps, actionsToProps)(Where)