import { takeEvery, put, select } from 'redux-saga/effects'
import { CHANGE_VALUE, putFounded } from '../../store/Search/actions'
import { putAllPublicTracksFilterName } from '../../store/PublicPlaylists/actions';

export default function* () {
    yield takeEvery(CHANGE_VALUE, worker)
}

function* worker({ payload }) {
    let { value, type } = payload
    console.log(1, { CHANGE_VALUE, value, type });
    let items
    let searchAttr = 'name'
    if (type === "tracks") {
        items = yield select(state => state.tracks.tracks)
    } else if (type === "objects") {
        searchAttr = 'fullName'
        items = yield select(state => state.objects.allObjects)
    } else if (type === "playlists") {
        items = yield select(state => state.playlists.allPlaylists)
    }

    else if (type === "songs") {
        items = [];
        yield put(putAllPublicTracksFilterName(value))

    }
    if (value !== "") {
        value = value.toLowerCase()

        if (items && items[0]) {
            items = items.filter(item =>
                item[searchAttr].toLowerCase().indexOf(value) !== -1 || (item['id'].toString().indexOf(value) !== -1)
            )
        }
    }
    console.log(2, { items });


    yield put(putFounded({
        type: type,
        founded: items,
        updated: new Date().getTime()
    }))
}