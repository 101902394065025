import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { LOAD_RUBRICS, loading, loadRubrics, putRubrics } from '../../../store/Modal/types/PublicSongsFilter/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_RUBRICS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        let rubrics = {}
        const selected_list = yield select(state => state.public_playlists.selected[0])
        const fetchedRubrics = yield call(fetchRubrics, selected_list)

        if (fetchedRubrics.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_RUBRICS}.`, fetchedRubrics],
                text: "Рубрики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putRubrics({ ...rubrics }))

        } else {
            let rr = fetchedRubrics.success

            let tempo = Object.values(rr.tempo)
            tempo = [tempo[0], tempo[tempo.length - 1]]

            rr.tempo = tempo
            // const ordered = Object.keys(rr.tempo).sort().reduce(
            //     (obj, key) => {
            //         obj[key] = rr.tempo[key];
            //         return obj;
            //     },
            //     {}
            // );
            // rr.tempo = ordered;
            yield put(putRubrics({
                rubrics: { ...rr },
                playlist: selected_list,
                filter_params: {
                    genre_name: [],
                    tempo: {
                        from: tempo[0],
                        to: tempo[1]
                    }
                },
                filter_params_initial: {
                    genre_name: [],
                    tempo: {
                        from: tempo[0],
                        to: tempo[1]
                    }
                }
            }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_RUBRICS}.`, e],
            text: "Рубрики не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putRubrics({}))
    }

    finally {
        yield put(loading(false))
    }
}

function fetchRubrics(selected_list) {
    return axios.get(!!selected_list ? `/playlist/tracks/rubric?id=${selected_list}` : '/playlist/tracks/rubric')
        .then(response => response.data)
}
