import React from 'react'
import PropTypes from 'prop-types'
import moment, { localeData } from 'moment'

import outside from '../../../utilities/outside'
import { cl } from '../../../utilities/cl'
import calcNewElementPosition from '../../../utilities/calcNewElementPosition'
import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'

import SvgCalendar from '../../SVG/Calendar'

import Single from './Single'
import Between from './Between'

// const data = {
//     className: "",
//     title: "",
//     onChange: "",
//     type: "",
//     variant: "",
//     durationInput: false,
//     format: "DD[.]MM[.]YYYY",
//     formatFunc: {(date) => russifyDate(moment(date).format("D MMMM YYYY"), true)},
//     disabled: false,
//     data: {
//         oneDay: false,

//         disabled: "past", // "future"
//         disabledDates: [],
//         disabledPeriod: {
//             from: "2020-07-15",
//             to: "2020-07-20",
//         },

//         // default: "2020-07-15",
//         default: {
//             from: "2020-07-15",
//             to: "2020-07-20",
//         },
//     }
// }


class Datepicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: this.props.titles?.main || "",
            dates: "",
            opened: false,
            selected: {},
            frozen: {},
            selectedMode: false,
            calendarPosition: {
                top: 0,
                left: 0,
            }
        }

        this.openRef = React.createRef()
        this.contentRef = React.createRef()

        this.toggleOpened = this.toggleOpened.bind(this)
        this.hClose = this.hClose.bind(this)
        this.hSave = this.hSave.bind(this)
        this.hCancel = this.hCancel.bind(this)

        this.updateLastSelected = this.updateLastSelected.bind(this)
        this.frozeSelected = this.frozeSelected.bind(this)

        this.hChange = this.hChange.bind(this)
        this.formatDates = this.formatDates.bind(this)
        this.getDisplayingDates = this.getDisplayingDates.bind(this)
    }

    toggleOpened() {
        if (this.props.disabled === true) return

        const position = calcNewElementPosition(this.openRef.current, this.contentRef.current, "center", { top: 37 })

        this.setState(state => {
            const opened = !state.opened

            if (opened) {
                this.frozeSelected(state.selected)
                document.addEventListener("click", this.hClose)
            }

            return {
                opened: opened,
                calendarPosition: {
                    top: `${position.top}px`,
                    left: `${position.left}px`
                }
            }
        })
    }

    hClose(e) {
        if (e.target === this.openRef.current || (this.contentRef.current && this.openRef.current.contains(e.target))) {
            return true
        }
        if (e.target !== this.contentRef.current && this.contentRef.current && !this.contentRef.current.contains(e.target)) {
            this.setState({ opened: false })
            document.removeEventListener("click", this.hClose)

        } else if (e.target.dataset?.role) {
            this.setState({ opened: false })
            document.removeEventListener("click", this.hClose)

            if (e.target.dataset?.role === "cancel") {
                this.hCancel()
            }
            if (e.target.dataset?.role === "save") {
                this.hSave(this.state.lastSelected)
            }
        }
    }

    hSave(selected) {
        if (typeof this.props.onChange === "function") {
            this.props.onChange(selected)
        }

        this.setState(() => {
            const dates = this.getDisplayingDates(selected)

            return {
                selected: selected,
                dates: dates,
                selectedMode: !!dates.length,
                ever: selected.ever
            }
        })
    }

    hCancel() {
        this.hSave(this.state.frozen)
    }

    hChange(selected) {
        console.log({ selected });

        if (window.outerWidth > MEDIA_BREAKPOINT_LG) {
            this.hSave(selected)
        } else {
            this.hSave(selected)
            this.updateLastSelected(selected)
        }
    }

    updateLastSelected(selected) {
        this.setState({ lastSelected: selected })
    }

    frozeSelected(selected) {
        this.setState({ frozen: selected })
    }

    render() {
        let {
            className = "",
            titles,
            label,
            type,
            variant,
            disabled,
            data = {},
            durationInput
        } = this.props
        let {
            title,
            dates,
            selectedMode,
            opened,
            calendarPosition
        } = this.state

        className = cl(
            "FormDatepicker",
            { "FormDatepicker_icon": variant === "icon" },
            { "disabled": disabled },
            className,
        )

        return (
            <div className={className}>

                {label
                    && <div className="FormDatepicker__label">
                        {label}
                    </div>
                }

                <div className="FormDatepicker__picker-control">
                    <div
                        ref={this.openRef}
                        className={cl(
                            "FormDatepicker__open",
                            { "selected": selectedMode },
                        )}
                        onClick={this.toggleOpened}
                    >
                        <SvgCalendar variant="dark" size="sm" title={title} />
                        <div className="placeholder">
                            {title}
                        </div>
                        <div className="date">
                            {dates}
                        </div>
                    </div>

                    {outside(
                        <div
                            className={cl(
                                "FormDatepicker__wrap",
                                "mobile-modal",
                                { "opened": opened },
                            )}
                            style={{ ...calendarPosition }}
                        >
                            <div
                                ref={this.contentRef}
                                className="FormDatepicker__content mobile-modal__content"
                            >
                                <div className="FormDatepicker__title mobile-modal__title">
                                    {title}
                                </div>

                                {type === "single" &&
                                    <Single
                                        oneDay={data.oneDay}
                                        oneDate={data.oneDate}
                                        disabled={data.disabled}
                                        disabledType={data.disabledType}
                                        disabledDates={data.disabledDates}
                                        disabledPeriod={data.disabledPeriod}
                                        default={data.default}

                                        title={titles?.from}
                                        format={"YYYY[-]MM[-]DD"}
                                        onChange={this.hChange}
                                    />
                                }

                                {type === "between" &&
                                    <Between
                                        oneDay={data.oneDay}
                                        oneDate={data.oneDate}
                                        disabled={data.disabled}
                                        disabledType={data.disabledType}
                                        disabledDates={data.disabledDates}
                                        disabledPeriod={data.disabledPeriod}
                                        default={data.default}

                                        titles={titles}
                                        format={"YYYY[-]MM[-]DD"}
                                        onChange={this.hChange}
                                        durationInput={durationInput}
                                    />
                                }

                                <div className="FormDatepicker__buttons mobile-modal__buttons">
                                    <div className="cancel" data-role="cancel">Отменить</div>
                                    <div className="save" data-role="save">Сохранить</div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        )
    }

    getDisplayingDates(selected) {
        selected = this.formatDates(selected)
        let dates

        if (typeof selected === "string" && selected.length) {
            dates = selected
        } else if (typeof selected === "object" && selected.from?.length && selected.from !== "Invalid date") {
            let date = selected.from
            if (selected.to?.length && selected.to !== "Invalid date") date += ` — ${selected.to}`
            dates = date
        } else {
            dates = ""
        }
        return dates
    }

    formatDates(selected) {
        const format =
            (this.props.format?.length)
                ? this.props.format
                : "DD[.]MM[.]YYYY"

        if (typeof selected === "string" && selected.length && selected.indexOf(".") === -1) {
            selected =
                (typeof this.props.formatFunc === "function")
                    ? this.props.formatFunc(selected)
                    : moment(selected).format(format)

        } else if (typeof selected === "object" && selected.from?.length && selected.from.indexOf(".") === -1) {
            selected.from =
                (typeof this.props.formatFunc === "function")
                    ? this.props.formatFunc(selected.from)
                    : moment(selected.from).format(format)

            if (selected.to?.length && selected.to.indexOf(".") === -1) {
                selected.to =
                    (typeof this.props.formatFunc === "function")
                        ? this.props.formatFunc(selected.to)
                        : moment(selected.to).format(format)
            }
        }
        return selected
    }
}

Datepicker.propTypes = {
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    onSelect: PropTypes.func
}

export default Datepicker