import { takeEvery, select, put, call } from 'redux-saga/effects'
import { loading, saving } from '../../../store/Settings/actions'
import { loadObjects, updateObject } from '../../../store/Objects/actions'
import { SAVE_SETTINGS, loadSettings } from '../../../store/Settings/actions/sets/Object'
import { cleanChanged } from '../../../store/Settings/actions/Changed'
import { getCleanedSettings } from '../../../utilities/getCleanedSettings'
import { getChangedSettings } from '../../../utilities/getChangedSettings'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { showAlertError, showAlertInfo, showAlertSuccess } from '../../../store/Alert/actions'
import axios from 'axios'
import { objectSort } from '../../Objects/loadObjects'
import { loadItems } from '../../../store/Settings/actions/sets/Object/Schedule'

export default function* () {
    yield takeEvery(SAVE_SETTINGS, worker)
}

function* worker({ payload }) {
    const { type, stateKey, fields } = payload

    //Маркер для перезагрузки рассписания.
    let needUpdateSchedule;

    try {
        let selectedObjects = yield select(state => state.objects.selected || []),
            tags

        let changedMap = yield select(state => {
            return state.settings.changed?.[type]?.[stateKey] || undefined;
        })


        let settings = yield select(state => state.settings.settings?.[type]?.[stateKey] || null)

        //Маркер для перезагрузки рассписания.
        needUpdateSchedule = 'msgroup_id' in settings;



        if (!changedMap || !settings) {

            yield put(showAlertInfo({
                text: "Нет изменений для сохранения."
            }))

            return
        }

        yield put(saving({
            type,
            stateKey,
            saving: true,
        }))
        let ar
        // Обрабатываем tags
        if (typeof settings["tags"] === "string" && typeof settings["__savedTags"] === "string") {
            let savedTags = settings["__savedTags"].split(","),
                nextTags = []

            tags = settings["tags"].split(",")

            if (tags.length === 1 && tags[0] === "") tags = []
            if (savedTags.length === 1 && savedTags[0] === "") savedTags = []

            savedTags.map(tag => {
                if (tags.indexOf(tag) === -1) {
                    nextTags.push(`-${tag}`)
                }
            })
            tags.map(tag => {
                if (savedTags.indexOf(tag) === -1) {
                    nextTags.push(`+${tag}`)
                }
            })

            settings["tags"] = nextTags.join(",");


        }



        // yield put(loading(true))
        // Сохраняем
        let fetchedSave = yield call(fetchSave, selectedObjects, settings, changedMap)

        // Ошибка
        if (fetchedSave.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SAVE_SETTINGS}.`, fetchedSave],
                text: "Не удалось сохранить изменения. Попробуйте повторить."
            }))

            // Успех
        } else {
            yield put(showAlertSuccess({
                responses: [`Успешное выполнение ${SAVE_SETTINGS}   .`, fetchedSave],
                text: "Изменения успешно сохранены."
            }))


            let statusList = yield select(state => state.objects.statusList)


            const statusObject = statusList.reduce((acc, current) => {
                acc[current.id] = current.name;
                return acc;
            }, {})

            if(fetchedSave.success?.length){
                fetchedSave.success[0] = {...fetchedSave.success[0], streamlink: settings['streamlink'] || '' }
            }

            let listObjectEntity = objectSort(fetchedSave.success, statusObject)[0]

            listObjectEntity['tags'] = tags



            yield put(updateObject(listObjectEntity))

            yield put(cleanChanged({
                type,
                stateKey,
            }))

        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_SETTINGS}.`, e],
            text: "Не удалось сохранить изменения. Попробуйте повторить."
        }))
    } finally {
        yield put(saving({
            type,
            stateKey,
            saving: false,
        }))
        // if (needUpdateSchedule) {
        //     yield put(loadItems({
        //         stateKey,
        //         required: true
        //     }))
        // }
    }
}

function fetchSave(ms_id, settings, changedMap) {
    const id = ms_id;//.join(",")
    settings = getCleanedSettings(settings)
    settings = getChangedSettings(changedMap, settings)

    let formData = new FormData()
    for (let name in settings) {
        formData.set(name, settings[name])
    }

    return axios(
        {
            url: "/msbox/update",
            method: "POST",
            data: formData,
            params: {
                id: id
            }
        }
    )
        .then(response => response.data)
}