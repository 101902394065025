import React from 'react'

import SvgClose from '../../SVG/Close'


const Header = ({ title, addedTitleElement, toggleOpened, hideCloseButton }) => {
    React.useEffect(() => {
        
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            toggleOpened(); 
          }
        };
    
        
        document.addEventListener('keydown', handleKeyDown);
    
        
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [toggleOpened]);
    
    return (
        <header className={`Modal__header ${!title && !addedTitleElement ? "Modal__header_m-0" : ""}`}>
            <h2>{title}</h2>

            {addedTitleElement &&
                <div className="Modal__addedTitleElement">
                    {addedTitleElement}
                </div>
            }

            {!hideCloseButton && <div className="close" onClick={toggleOpened}>
                <SvgClose variant="dark" size="sm3" />
            </div>}
        </header>
    )
}



export default Header