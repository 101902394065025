import { DO_LOGOUT } from '../Login/actions'
import {
    LOAD_VIDEOLISTS,
    UPDATE_VIDEOLIST,
    SET_CURRENT_VIDEOLIST,
    SORT_VIDEOS,
    SORT_VIDEOLISTS,
    ADD_VIDEO_TO_LIST,
    LOADING,
    DELETE_VIDEOLISTS,
    PUT_VIDEOLISTS,
    TOGGLE_SELECTED_VIDEOLIST,
    TOGGLE_SELECTED_VIDEO
} from './actions'

const initialState = {
    loading: false,
    allVideolists: [],
    currentVideolist: undefined,
    selected: [],
    selected_video: []
}
const sortByField = function (a, b, field, direction) {
    if (a[field] < b[field]) {
        return direction === "up" ? -1 : 1
    }
    if (a[field] > b[field]) {
        return direction === "up" ? 1 : -1
    }
    return 0
}
const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SORT_VIDEOS:
            const currentVideolistSongs = Array.from(state.currentVideolist.songs);
            if (payload.sort_type === "name") {
                currentVideolistSongs.sort((a, b) => sortByField(a, b, 'name', payload.direction))
            } else if (payload.sort_type === "duration") {
                currentVideolistSongs.sort((a, b) => sortByField(a, b, 'cliptime', payload.direction))
            } else if (payload.sort_type === "added") {
                currentVideolistSongs.sort((a, b) => sortByField(a, b, 'ctime', payload.direction))
            } else if (payload.sort_type === "id") {
                currentVideolistSongs.sort((a, b) => sortByField(a, b, 'id', payload.direction))
            }
            return {
                ...state,
                currentVideolist: {...state.currentVideolist, songs: currentVideolistSongs}
            }
        case SORT_VIDEOLISTS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "songs") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (a.totalcount < b.totalcount) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalcount > b.totalcount) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "size") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (a.totalsize < b.totalsize) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalsize > b.totalsize) {
                                return payload.direction === "up" ? 1 : -1
                            } else {
                                return 0
                            }
                        }),
                }
            } else if (payload.sort_type === "duration") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (a.totaltime === null) {
                                a.totaltime = `00:00:00`
                            }
                            if (b.totaltime === null) {
                                b.totaltime = `00:00:00`
                            }
                            let atot = Number(a.totaltime.split(":")[0]) * 3600 + Number(a.totaltime.split(":")[1]) * 60 + Number(a.totaltime.split(":")[2])
                            let btot = Number(b.totaltime.split(":")[0]) * 3600 + Number(b.totaltime.split(":")[1]) * 60 + Number(b.totaltime.split(":")[2])
                            if (atot < btot) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (atot > btot) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
        case LOAD_VIDEOLISTS:
            const all = state.allVideolists ? [...state.allVideolists] : []
            return {
                ...state,
                allVideolists: [],
                selected: [...state.selected]
            }
        case PUT_VIDEOLISTS:
            return {
                ...state,
                allVideolists: payload.videolists,
                //tracksAssoc: payload.tracksAssoc,
            }
        case SET_CURRENT_VIDEOLIST:
            return {
                ...state,
                currentVideolist: payload.videolist,
                //selected: [payload.videolist.id]
            }
        case UPDATE_VIDEOLIST:
            //console.log(payload.videolist)
            console.log({payload});
            
            state.allVideolists.map(videolist => {
                if (videolist.id === payload.videolist?.id) {
                    console.log({videolist});
                    
                    videolist.songs = [...payload.videolist.songs];
                    if (payload.videolist.totalcount) {
                        videolist.totalcount = payload.videolist.totalcount;
                    }
                }
                return videolist
            })
            return {
                ...state,
                allVideolists: state.allVideolists,
                selected: [payload.videolist.id]
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case ADD_VIDEO_TO_LIST:
            state.allVideolists.map(videolist => {
                if (videolist.id === payload.id_videolist) {
                    videolist.songs = [...payload.songs]
                }
            })
            return {
                ...state,
                allVideolists: state.allVideolists

            }
        case DELETE_VIDEOLISTS:
            return {
                ...state,
                loading: true,
                selected: []
            }
        case TOGGLE_SELECTED_VIDEOLIST:
            const {id, selected, simple, required} = payload
            if (simple === true) {
                return {
                    ...state,
                    selected,
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(playlistId => playlistId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(playlistId => playlistId !== id)
                }
            }
        case TOGGLE_SELECTED_VIDEO:
            const {id_video, selected_video, onlyOne, setAll } = payload
            if (setAll === true) {
                return {
                    ...state,
                    selected_video: id_video,
                }
            } else if (selected_video === true) {
                return {
                    ...state,
                    selected_video:
                        (state.selected_video.indexOf(id_video) === -1)
                            ? state.selected_video.concat([id_video])
                            : state.selected_video
                }
            } else if (selected_video === false) {
                return {
                    ...state,
                    selected_video:
                        (state.selected_video.indexOf(id_video) !== -1)
                            ? state.selected_video.filter(objectId => objectId !== id_video)
                            : state.selected_video
                }
            } else {
                if (onlyOne === true) {
                    return {
                        ...state,
                        selected_video:
                            (state.selected_video.indexOf(id_video) === -1)
                                ? [id_video]
                                : []
                    }
                } else {
                    return {
                        ...state,
                        selected_video:
                            (state.selected_video.indexOf(id_video) === -1)
                                ? state.selected_video.concat([id_video])
                                : state.selected_video.filter(objectId => objectId !== id_video)
                    }
                }
            }

            case DO_LOGOUT:
                return initialState;

    }
    return state
}

export default reducer