import { DO_LOGOUT } from '../Login/actions'
import {
    LOAD_PLAYLISTS, LOAD_PLAYLIST, LOADING, DELETE_PLAYLISTS,
    PUT_PLAYLISTS, TOGGLE_SELECTED_PLAYLIST,
    TOGGLE_SELECTED_SONG, ADD_SONGS_TO_PLAYLIST,
    SORT_PLAYLISTS, SORT_SONGS, LOADING_AVMAP_BINDINGS, PUT_AVMAP_BINDINGS, LOADING_AVMAP, UPDATE_PLAYLIST
} from './actions'

const inititalState = {
    loading: false,
    allPlaylists: [],
    selected: [],
    selected_song: [],
    avmap_bindings: [],
    loading_avmap: false
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case SORT_SONGS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.name < b.name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.name > b.name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "duration") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.cliptime < b.cliptime) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.cliptime > b.cliptime) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "temp") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.tempo < b.tempo) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.tempo > b.tempo) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "genre") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.genre_name < b.genre_name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.genre_name > b.genre_name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "author") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.songauthor_name < b.songauthor_name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.songauthor_name > b.songauthor_name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "player") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.player_name < b.player_name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.player_name > b.player_name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "album") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.album_name < b.album_name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.album_name > b.album_name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            } else if (payload.sort_type === "added") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).map(playlist => {
                            if (playlist.id === payload.id) {
                                let songs = Object.entries(playlist.songs).sort(([, a], [, b]) => {
                                    if (a.year < b.year) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.year > b.year) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                let obj = {}
                                songs = songs.map(item => {
                                    return item[1]
                                })
                                for (let i = 0; i < songs.length; i++) {
                                    obj[Symbol(songs[i].id).toString()] = songs[i]
                                }
                                playlist.songs = { ...obj }
                                return { ...playlist }
                            } else {
                                return { ...playlist }
                            }

                        })
                }
            }
        case SORT_PLAYLISTS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "songs") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).sort((a, b) => {

                            if (a.totalcount < b.totalcount) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalcount > b.totalcount) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "size") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).sort((a, b) => {
                            if (a.totalsize < b.totalsize) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalsize > b.totalsize) {
                                return payload.direction === "up" ? 1 : -1
                            } else {
                                return 0
                            }
                        }),
                }
            } else if (payload.sort_type === "duration") {
                return {
                    ...state,
                    allPlaylists:
                        Array.from(state.allPlaylists).sort((a, b) => {
                            if (a.totaltime === null) {
                                a.totaltime = `00:00:00`
                            }
                            if (b.totaltime === null) {
                                b.totaltime = `00:00:00`
                            }
                            let atot = Number(a.totaltime.split(":")[0]) * 3600 + Number(a.totaltime.split(":")[1]) * 60 + Number(a.totaltime.split(":")[2])
                            let btot = Number(b.totaltime.split(":")[0]) * 3600 + Number(b.totaltime.split(":")[1]) * 60 + Number(b.totaltime.split(":")[2])
                            if (atot < btot) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (atot > btot) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
        case LOADING_AVMAP_BINDINGS:
            return {
                ...state,
                // loading_avmap: payload
            }
        case PUT_AVMAP_BINDINGS:
            return {
                ...state,
                avmap_bindings: payload
            }
        case LOAD_PLAYLISTS:
            return {
                ...state,
                allPlaylists: [],
                selected: [...state.selected]
            }
        case PUT_PLAYLISTS:
            return {
                ...state,
                allPlaylists: payload.playlists,
                //tracksAssoc: payload.tracksAssoc,
            }
        case UPDATE_PLAYLIST:
            console.log({ UPDATE_PLAYLIST, payload });

            state.allPlaylists.map(playlist => {
                if (playlist.id === payload.playlist.id) {
                    playlist.songs = payload.playlist.songs;
                    if (typeof payload.playlist.totalcount !== 'undefined') {
                        playlist.totalcount = payload.playlist.totalcount;
                    }
                    if (typeof payload.playlist.totalsize !== 'undefined') {
                        playlist.totalsize = payload.playlist.totalsize;
                    }
                    if (typeof payload.playlist.totaltime !== 'undefined') {
                        playlist.totaltime = payload.playlist.totaltime;
                    }
                    if (typeof payload.playlist.totalsize2 !== 'undefined') {
                        playlist.totalsize2 = payload.playlist.totalsize2;
                    }
                }
                return playlist
            })
            return {
                ...state,
                allPlaylists: state.allPlaylists,
                //selected: [payload.playlist.id]
            }
        case ADD_SONGS_TO_PLAYLIST:
            state.allPlaylists.map(playlist => {
                if (playlist.id === payload.id_playlist) {
                    playlist.songs = [...payload.songs]
                }
                return playlist
            })
            return {
                ...state,
                allPlaylists: state.allPlaylists
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case LOADING_AVMAP:
            return {
                ...state,
                loading_avmap: payload
            }
        case DELETE_PLAYLISTS:
            const { selectedPlaylists } = payload
            return {
                ...state,
                loading: true,
                selected: []
            }
        case TOGGLE_SELECTED_PLAYLIST:
            const { id, selected, simple, required } = payload
            if (simple === true) {
                return {
                    ...state,
                    selected: [...selected],
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(playlistId => playlistId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(playlistId => playlistId !== id)
                }
            }
        case TOGGLE_SELECTED_SONG:
            const { id_song, selected_song, onlyOne } = payload
            if (selected_song === true) {
                return {
                    ...state,
                    selected_song:
                        (state.selected_song.indexOf(id_song) === -1)
                            ? state.selected_song.concat([id_song])
                            : state.selected_song
                }
            } else if (selected_song === false) {
                return {
                    ...state,
                    selected_song:
                        (state.selected_song.indexOf(id_song) !== -1)
                            ? state.selected_song.filter(objectId => objectId !== id_song)
                            : state.selected_song
                }
            } else {
                if (onlyOne === true) {
                    return {
                        ...state,
                        selected_song:
                            (state.selected_song.indexOf(id_song) === -1)
                                ? [id_song]
                                : []
                    }
                } else {
                    return {
                        ...state,
                        selected_song:
                            (state.selected_song.indexOf(id_song) === -1)
                                ? state.selected_song.concat([id_song])
                                : state.selected_song.filter(objectId => objectId !== id_song)
                    }
                }
            }
        case DO_LOGOUT:
            return inititalState;

    }
    return state
}

export default reducer