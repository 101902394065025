import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { loading, putPublicFilterParams, putRubrics, UPDATE_FILTER_PARAMS, UPDATE_PUBLIC_FILTER_PARAMS } from '../../../store/Modal/types/PublicSongsFilter/actions'
import { loading_assoc, putAllPublicTracks } from '../../../store/PublicPlaylists/actions'
import { showAlertError } from '../../../store/Alert/actions'
import { putAssocPublicTracks } from '../../../store/PublicPlaylists/actions'
import { closeModal } from '../../../store/Modal/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(UPDATE_PUBLIC_FILTER_PARAMS, worker)
}

function* worker({ payload }) {
    yield put(loading_assoc(true))
    yield put(putPublicFilterParams(payload))

    console.log(UPDATE_PUBLIC_FILTER_PARAMS, payload)
    
    const public_tracks_table = yield select(state => state.public_playlists.selected ? state.public_playlists.assocPublicTracksTable : state.public_playlists.allPublicTracks)

    const search_name = yield select(state => state.public_playlists.allPublicFilterName)


    let pay = { ...payload }//нельзя просто присваивать объекты, они работают по ссылке и все ниже не имеет смысла
    if (search_name?.length) {
        pay.name = search_name;
    }
    if (pay.tempo?.length === 0) {
        delete pay.tempo
    }
    if (pay.genre_name?.length === 0) {
        delete pay.genre_name
    }
    if (pay.songauthor_name?.length === 0) {
        delete pay.songauthor_name
    }
    if (public_tracks_table.sort_field) {
        pay[`sort[${[public_tracks_table.sort_field]}]`] = public_tracks_table.sort_dir;
    }
    try {
        const fetchedFiltered = yield call(fetchFiltered, { ...pay })

        if (fetchedFiltered.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_PUBLIC_FILTER_PARAMS}.`, fetchFiltered],
                text: "Параметры фильтра не применены. Попробуйте перезагрузить страницу."
            }))
            // yield put(putRubrics({ ...rubrics }))

        } else {
            yield put(closeModal("publicAllSongsFilter"))
            const result = fetchedFiltered.success

            const assocPublicTracks = new Map();


            result.forEach(track => {
                assocPublicTracks.set(track.id, {
                    ...track,
                    filename: buildGETUrl("clip/play", { id: track.id })
                });
            });


            yield put(putAllPublicTracks(result))

        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_PUBLIC_FILTER_PARAMS}.`, e],
            text: "Параметры фильтра не применены. Попробуйте перезагрузить страницу."
        }))
        //yield put(putRubrics({}))
    }

    finally {
        yield put(loading_assoc(false))
    }
}

function fetchFiltered(data) {
    if (data.tempo?.to) {
        data.tempo = `${data.tempo.from}-${data.tempo.to}`

    }
    console.log('function fetchFiltered(data) {', {data});

    if(data['sort[child]']){
        data['sort[id]'] = data['sort[child]']
        delete data['sort[child]']
    }
    
    return axios.get("/playlist/tracks/search", {
        params: {
            ...data
        }
    })
        .then(response => response.data)
}
