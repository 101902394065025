import React from 'react'
import { Accordion, Collapse } from 'react-bootstrap'

import { cl } from '../../../utilities/cl'
import CollapsibleCard from '../../CollapsibleCard'
import CollapsiblePlaylist from '../../CollapsiblePlaylist'
import Collapsible from '../../Space/Collapsible/Collapsible'

import SvgAudio from '../../SVG/Audio'

const PlaylistItem = ({
    id,
    name,
    onClick,
    loadPlaylist,
    selected,
    selected_public,
    markItems,
    variant,
    totaltime,
    totalsize,
    totalcount
}) => {

    const className = cl(
        // "Playlists__item",
        "selectable-item",
        { "Playlist": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )

    const hClick = (id) => {
        //loadPlaylist(id);
    }
    // let size = Math.floor(totalsize / 1024 / 1024);
    // size = !size ? `${Math.floor(totalsize / 1024 )} Kb` : `${size} Mb`
    return (
        <div className={className} data-playlist-id={id} onClick={(e) => hClick(id)}>
            <div className="Playlist__header">
                <div className="Playlists__grid">
                    <div className="play">
                        <span>
                            <SvgAudio variant="dark-lg" size="sm1" title="Воспроизвести" />
                        </span>
                    </div>
                    <div className="name">
                        {/* <h4>{name.length > 25 ? `${name.slice(0, 25)}…` : name}</h4> */}
                        <h4>{name}</h4>
                    </div>
                    <div className="tracks_count">{totalcount}</div>
                    <div className="size">
                        {totalsize}
                    </div>
                    <div className="duration">
                        {totaltime === null ? `00:00:00` : totaltime}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlaylistItem