import { all } from 'redux-saga/effects'
import { store } from '../connect'
import AppAuth from './App/auth'
import DoLogin from './Login/auth'
import DoSubLogin from './Login/subLogin'
import DoLogout from './Login/logout'
import PassRecovery from './Login/recovery'

import MarksAddMark from './Marks/addMark'
import MarksUpdateCurrentMark from './Marks/updateCurrentMark'

import ClearAdvert from './AdvertClear/clearAdvert'

import ModalLoadRubrics from './Modal/PublicSongsFilter/loadRubrics'
import ModalLoadPublicFilter from './Modal/PublicSongsFilter/loadPublicFilter'
import ModalLoadFiltered from './Modal/PublicSongsFilter/updateFilter'
import ModalLoadPublicFiltered from './Modal/PublicSongsFilter/updatePublicFilter'


import ModalOverflowHidden from './Modal/overflowHidden'

import ModalAdsCreateCreateAds from './Modal/AdsCreate/createAds'
import ModalAdsCreateCreateAdsExtra from './Modal/AdsCreate/createAdsExtra'
import ModalAdsCopyCopyAds from './Modal/AdsCopy/copyAds'

import ModalPlaylistCreate from './Modal/PlaylistCreate/createPlaylist'
import ModalPlaylistEdit from './Modal/PlaylistCreate/editPlaylist'

import ModalVideolistCreate from './Modal/VideolistCreate/createVideolist'
import ModalVideolistEdit from './Modal/VideolistCreate/editVideolist'

import ModalVideoCreate from './Modal/VideoCreate/createVideo'

import ModalAdsEditSaveAds from './Modal/AdsEdit/saveAds'
import ModalAdsEditSaveAdsExtra from './Modal/AdsEdit/saveAdsExtra'
import ModalAdsEditUpdateModalStore from './Modal/AdsEdit/updateModalStore'

import ModalAdsReportSortData from './Modal/AdsReport/sortData'
import ModalAdsReportLoadReport from './Modal/AdsReport/loadReport'

import ModalAdvertCreateCreateAdvert from './Modal/AdvertCreate/createAdvert'
import ModalAdvertCreateDeleteAdvert from './Modal/AdvertCreate/deleteAdvert'

import ModalCastinfoDownload from './Modal/Castinfo/download'

import ModalMediaplanLoadMediaplan from './Modal/Mediaplan/loadMediaplan'
import ModalMediaplanRequestMediaplan from './Modal/Mediaplan/requestMediaplan'
import ModalVideoHistoryLoadVideoHistory from './Modal/VideoHistory/loadVideoHistory'

import ObjectsLoadObjects from './Objects/loadObjects'
import ObjectsSearchObjects from './Objects/searchObjects'
import ObjectsLoadIndentObjects from './Objects/loadIndentObjects'
import ObjectsUpdateSelectedByGroupsAndTags from './Objects/updateSelectedByGroupsAndTags'

import ObjectsByTagsPutObjectsTags from './ObjectsByTags/putObjectsTags'
import ObjectsByTagsToggleSelectedTags from './ObjectsByTags/toggleSelectedTags'

import ObjectsGroupCreateObjectsGroup from './ObjectsGroup/createObjectsGroup'
import ObjectsGroupDeleteObjectsGroup from './ObjectsGroup/deleteObjectsGroup'
import ObjectsGroupLoadObjectsGroup from './ObjectsGroup/loadObjectsGroup'
import ObjectsGroupSaveObjectsGroup from './ObjectsGroup/saveObjectsGroup'
import ObjectsGroupToggleSelectedGroup from './ObjectsGroup/toggleSelectedGroup'

import SearchInit from './Search/init'
import SearchSearch from './Search/search'
import ReloadFounded from './Search/reload'


import SelectTZLoadTz from './SelectTZ/loadTz'

import SettingsAdvertTracksSaveSettings from './Settings/Advert/Tracks/saveSettings'

import SettingsBackgroundListsAddToUsed from './Settings/BackgroundLists/addToUsed'
import SettingsBackgroundListsDeleteUsedItems from './Settings/BackgroundLists/deleteUsedItems'
import SettingsBackgroundListsLoadAvailList from './Settings/BackgroundLists/loadAvailList'
import SettingsBackgroundListsLoadUsedList from './Settings/BackgroundLists/loadUsedList'
import SettingsBackgroundListsMoveUsedItemTo from './Settings/BackgroundLists/moveUsedItemTo'
import SettingsBackgroundListsSaveUsedItemsMoving from './Settings/BackgroundLists/saveUsedItemsMoving'

import SettingsChangedPutChanged from './Settings/Changed/putChanged'

import SettingsObjectLoadSettings from './Settings/Object/loadSettings'
import SettingsObjectSaveSettings from './Settings/Object/saveSettings'

import SettingsObjectBackgroundAddItem from './Settings/Object/Background/addItem'
import SettingsObjectBackgroundSaveItems from './Settings/Object/Background/saveItems'
import SettingsObjectBackgroundDeleteItems from './Settings/Object/Background/deleteItems'
import SettingsObjectBackgroundLoadItems from './Settings/Object/Background/loadItems'
import SettingsObjectBackgroundSelectUsedVideo from './Settings/Object/Background/selectUsedVideo'

import SettingsObjectScheduleAddItem from './Settings/Object/Schedule/addItem'
import SettingsObjectScheduleDeleteItems from './Settings/Object/Schedule/deleteItems'
import SettingsObjectScheduleDeleteTrackFromPosition from './Settings/Object/Schedule/deleteTrackFromPosition'
import SettingsObjectScheduleLoadItems from './Settings/Object/Schedule/loadItems'
import SettingsObjectScheduleLoadTracks from './Settings/Object/Schedule/loadTracks'
import SettingsObjectScheduleSaveItemDaymask from './Settings/Object/Schedule/saveItemDaymask'
import SettingsObjectScheduleSaveItemTracksAndDaymask from './Settings/Object/Schedule/saveItemTracksAndDaymask'
import SettingsObjectScheduleSaveTrackForItem from './Settings/Object/Schedule/saveTrackForItem'

import SettingsObjectSilentAddItem from './Settings/Object/Silent/addItem'
import SettingsObjectSilentDeleteItems from './Settings/Object/Silent/deleteItems'
import SettingsObjectSilentLoadItems from './Settings/Object/Silent/loadItems'
import SettingsObjectSilentSaveItem from './Settings/Object/Silent/saveItem'

import SettingsObjectVolumeAddItem from './Settings/Object/Volume/addItem'
import SettingsObjectVolumeDeleteItems from './Settings/Object/Volume/deleteItems'
import SettingsObjectVolumeLoadItems from './Settings/Object/Volume/loadItems'
import SettingsObjectVolumeSaveItemSettings from './Settings/Object/Volume/saveItemSettings'

import SettingsClipsDeleteClip from './Settings/Clips/deleteClip'
import SettingsClipsLoadClips from './Settings/Clips/loadClips'
import SettingsClipsUpdateClip from './Settings/Clips/updateClip'

import SettingsPeriodsAddPeriod from './Settings/Periods/addPeriod'
import SettingsPeriodsLoadPeriods from './Settings/Periods/loadPeriods'
import SettingsPeriodsSavePeriod from './Settings/Periods/savePeriod'
import SettingsPeriodsCopyPeriod from './Settings/Periods/copyPeriod'
import SettingsPeriodsDeletePeriod from './Settings/Periods/deletePeriod'

import SettingsPresetsApplyPreset from './Settings/Presets/applyPreset'
import SettingsPresetsResetPreset from './Settings/Presets/resetPreset'
import SettingsPresetsLoadPresets from './Settings/Presets/loadPresets'
import SettingsPresetsUpdatePresetName from './Settings/Presets/updatePresetName'
import SettingsPresetsDeletePreset from './Settings/Presets/deletePreset'
import SettingsPresetsCreatePreset from './Settings/Presets/createPreset'

import TracksDeleteTracks from './Tracks/deleteTracks'
import TracksDeleteTracksExtra from './Tracks/deleteTracksExtra'
import TracksLoadTracks from './Tracks/loadTracks'
import TracksDownloadTracks from './Tracks/downloadTracks'

import SearchPanelClear from './SearchPanel/clearValue'

import ContentLoadPlaylists from './ContentPlaylists/loadPlaylists'
import ContentLoadPlaylist from './ContentPlaylists/loadPlaylist'
import ContentSelectSongsAll from './PublicPlaylists/selectSongsAll'
import ContentDeletePlaylists from './ContentPlaylists/deletePlaylists'
import ContentLoadAvmapBindings from './ContentPlaylists/loadAvmapBindings'

import ContentLoadVideolists from './ContentVideolists/loadVideolists'
import ContentLoadVideolist from './ContentVideolists/loadVideolist'
import ContentDeleteVideolists from './ContentVideolists/deleteVideolists'

import ContentLoadVideolistsAll from './ContentAllVideolists/loadVideolistsAll'
import ContentLoadVideolistsAllById from './ContentAllVideolists/loadVideolistsAllById'

import ContentLoadPublicPlaylists from './PublicPlaylists/loadPublicPlaylists'
import ContentLoadAssocPublicTracks from './PublicPlaylists/loadAssocPublicTracks'
import ContentAllTracks from './PublicPlaylists/loadAllPublicSongs'

import AddSongsToPlaylist from './ContentPlaylists/addSongsToPlaylist'
import AddVideoToList from './ContentVideolists/addVideoToList'
import DownloadVideos from './ContentVideolists/downloadVideos'

import ReportsObjectsState from './Reports/loadObjectsState'
import ReportAdvert from './Reports/Advert/loadAdvertReport'
import ReportMusic from './Reports/Music/loadMusicReport'
import ReportSystem from './Reports/System/loadSystemReport'

import SettingsTemplatesLoad from './Templates/loadTemplates'
import SettingsTemplatesUpdate from './Templates/updateTemplates'

import SettingsNotificationsLoad from './Notifications/loadNotifications'
import SettingsNotificationsUpdate from './Notifications/updateNotifications'

import SettingsRolesLoadSubusers from './Roles/loadSubusers'
import SettingsRolesLoadRoles from './Roles/loadRoles'
import SettingsRolesCreateSubuser from './Roles/createSubuser'
import SettingsRolesCreateRole from './Roles/createRole'
import SettingsRolesLoadAccountInfo from './Roles/loadAccountInfo'
import SettingsRolesDeleteSubuser from './Roles/deleteSubuser'
import SettingsRolesDeleteRole from './Roles/deleteRole'
import SettingsRolesUpdateSubuser from './Roles/updateSubuser'
import SettingsRolesUpdateRole from './Roles/updateRole'
import SettingsRolesUpdateAccount from './Roles/updateAccount'
import CopyVideoToList from './CopyVideoToList/copyVideoToList'

import LoadHelp from './Help/loadHelp'
import LoadHelpContent from './Help/loadHelpContent'
import NeedHelp from './Help/needHelp'

import LoadPVData from './Features/loadPvData'
import UpdatePVData from './Features/updatePvData'

import UpdateSong from './Modal/SongEdit/songEdit'


import axios from 'axios';
import { useHistory } from "react-router-dom";
import { loading } from '../store/App/actions';

let token = window.localStorage.getItem("sessionToken")
// axios.defaults.params = {}
// axios.defaults.params['token'] = token
axios.defaults.headers.common["token"] = token
//axios.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest'
axios.defaults.baseURL = 'https://a.ms-box.ru';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
    //console.log(config);
    const needLoading = ['/msbox/select', '/playlist/select', '/playlist/get', '/playlist/tracks/search',
        '/playlist/tracks/select', '/playlist/tracks/ids', '/playlist/set', '/videolist/set',
        '/timeline/clip/replace', '/timeline/clip/select', '/timeline/clip/insert', '/clip/select',
        //'/clip/insert','/clip/update', '/videolist/tracks/insert',
        '/clip/delete', '/docs/select'];
    //переделать на .some()
    for (const item of needLoading) {
        if (config.url.includes(item) && !store.getState().app.loading) {
            store.dispatch(loading(true));
            break;
        }
    }
    // needLoading.forEach((item) => {
    //     if (config.url.includes(item) && !store.getState().app.loading) {
    //         store.dispatch(loading(true));
    //     }
    // })

    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if (store.getState().app.loading) {
        store.dispatch(loading(false));
    }
    return response;
}, function (error) {
    store.dispatch(loading(false));
    if (401 === +error.response.status) {
        // handle error: inform user, go to login, etc
        localStorage.sessionToken = "";
        localStorage.removeItem('master')
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default function* root() {
    yield all([
        AppAuth(),
        DoLogin(),
        DoSubLogin(),
        DoLogout(),
        PassRecovery(),

        MarksAddMark(),
        MarksUpdateCurrentMark(),

        ClearAdvert(),

        ModalLoadRubrics(),
        ModalLoadPublicFilter(),
        ModalLoadFiltered(),
        ModalLoadPublicFiltered(),

        ModalOverflowHidden(),

        ModalAdsCreateCreateAds(),
        ModalAdsCreateCreateAdsExtra(),
        ModalAdsCopyCopyAds(),

        ModalPlaylistCreate(),
        ModalPlaylistEdit(),
        ModalVideolistCreate(),
        ModalVideolistEdit(),

        ModalVideoCreate(),

        ModalAdsEditSaveAds(),
        ModalAdsEditSaveAdsExtra(),
        ModalAdsEditUpdateModalStore(),

        ModalAdsReportSortData(),
        ModalAdsReportLoadReport(),

        ModalAdvertCreateCreateAdvert(),
        ModalAdvertCreateDeleteAdvert(),

        ModalCastinfoDownload(),

        ModalMediaplanLoadMediaplan(),
        ModalMediaplanRequestMediaplan(),
        ModalVideoHistoryLoadVideoHistory(),

        ObjectsLoadObjects(),
        ObjectsSearchObjects(),
        ObjectsLoadIndentObjects(),
        ObjectsUpdateSelectedByGroupsAndTags(),

        ObjectsByTagsPutObjectsTags(),
        ObjectsByTagsToggleSelectedTags(),

        ObjectsGroupCreateObjectsGroup(),
        ObjectsGroupDeleteObjectsGroup(),
        ObjectsGroupLoadObjectsGroup(),
        ObjectsGroupSaveObjectsGroup(),
        ObjectsGroupToggleSelectedGroup(),

        SearchInit(),
        SearchSearch(),
        ReloadFounded(),

        SelectTZLoadTz(),

        SettingsAdvertTracksSaveSettings(),

        SettingsBackgroundListsAddToUsed(),
        SettingsBackgroundListsDeleteUsedItems(),
        SettingsBackgroundListsLoadAvailList(),
        SettingsBackgroundListsLoadUsedList(),
        SettingsBackgroundListsMoveUsedItemTo(),
        SettingsBackgroundListsSaveUsedItemsMoving(),

        SettingsChangedPutChanged(),

        SettingsObjectLoadSettings(),
        SettingsObjectSaveSettings(),

        SettingsObjectBackgroundAddItem(),
        SettingsObjectBackgroundSaveItems(),
        SettingsObjectBackgroundDeleteItems(),
        SettingsObjectBackgroundLoadItems(),
        SettingsObjectBackgroundSelectUsedVideo(),

        SettingsObjectScheduleAddItem(),
        SettingsObjectScheduleDeleteItems(),
        SettingsObjectScheduleDeleteTrackFromPosition(),
        SettingsObjectScheduleLoadItems(),
        SettingsObjectScheduleLoadTracks(),
        SettingsObjectScheduleSaveItemDaymask(),
        SettingsObjectScheduleSaveItemTracksAndDaymask(),
        SettingsObjectScheduleSaveTrackForItem(),

        SettingsObjectSilentAddItem(),
        SettingsObjectSilentDeleteItems(),
        SettingsObjectSilentLoadItems(),
        SettingsObjectSilentSaveItem(),

        SettingsObjectVolumeAddItem(),
        SettingsObjectVolumeDeleteItems(),
        SettingsObjectVolumeLoadItems(),
        SettingsObjectVolumeSaveItemSettings(),

        SettingsClipsDeleteClip(),
        SettingsClipsLoadClips(),
        SettingsClipsUpdateClip(),

        SettingsPeriodsAddPeriod(),
        SettingsPeriodsLoadPeriods(),
        SettingsPeriodsSavePeriod(),
        SettingsPeriodsCopyPeriod(),
        SettingsPeriodsDeletePeriod(),

        SettingsPresetsApplyPreset(),
        SettingsPresetsResetPreset(),
        SettingsPresetsLoadPresets(),
        SettingsPresetsUpdatePresetName(),
        SettingsPresetsDeletePreset(),
        SettingsPresetsCreatePreset(),

        TracksDeleteTracks(),
        TracksDeleteTracksExtra(),
        TracksLoadTracks(),
        TracksDownloadTracks(),

        SearchPanelClear(),

        ContentLoadPlaylists(),
        ContentLoadPlaylist(),
        ContentSelectSongsAll(),
        ContentDeletePlaylists(),
        ContentLoadAvmapBindings(),

        ContentLoadPublicPlaylists(),
        ContentLoadAssocPublicTracks(),
        ContentAllTracks(),

        ContentLoadVideolists(),
        ContentLoadVideolist(),
        ContentDeleteVideolists(),
        ContentLoadVideolistsAll(),
        ContentLoadVideolistsAllById(),
        CopyVideoToList(),

        AddSongsToPlaylist(),
        AddVideoToList(),
        DownloadVideos(),

        ReportsObjectsState(),
        ReportAdvert(),
        ReportMusic(),
        ReportSystem(),

        SettingsTemplatesLoad(),
        SettingsTemplatesUpdate(),

        LoadHelp(),
        LoadHelpContent(),
        NeedHelp(),

        SettingsNotificationsLoad(),
        SettingsNotificationsUpdate(),

        SettingsRolesLoadSubusers(),
        SettingsRolesLoadRoles(),
        SettingsRolesLoadAccountInfo(),
        SettingsRolesCreateSubuser(),
        SettingsRolesCreateRole(),
        SettingsRolesDeleteSubuser(),
        SettingsRolesDeleteRole(),
        SettingsRolesUpdateSubuser(),
        SettingsRolesUpdateRole(),
        SettingsRolesUpdateAccount(),

        LoadPVData(),
        UpdatePVData(),

        UpdateSong(),

    ])
}