import React, {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'

import {openModal, closeModal} from '../../../../../store/Modal/actions'
import {deleteAdvert} from '../../../../../store/Modal/types/AdvertCreate/actions'

import {cl} from '../../../../../utilities/cl'
import {isFunc} from '../../../../../utilities/isFunc'
import {generateStateKey} from '../../../../../utilities/generateStateKey'

import Select from '../../../../Form/Select'
import ButtonCircle from '../../../../ButtonCircle'
import SvgPlus from '../../../../SVG/Plus'
import SvgTrash from '../../../../SVG/Trash'
import { OBJECT_SCHEDULE } from '../../../../../store/Settings/sets'


const ChangeTrack = ({
    className,
    label = "",
    stateKey,
    side,
    position,

    tracks = {},
    selectedTrack,

    deletingAdvert,

    onChange,
    deleteAdvert,
    openModal,
    closeModal,
}) => {

    const [selected, setSelected] = useState(selectedTrack)
    const [searchWord, setSearchWord]     = useState("")

    useEffect(() => {
        if(isFunc(onChange)) {
            onChange({
                stateKey,
                side,
                position,
                value: selectedTrack,
            })
        }
    }, [])

    const tracksList = useMemo(() => {
        // let list = [{
        //     title: tracks["NULL"].name,
        //     value: tracks["NULL"].id,
        // }]
        let list = []
        Object.entries(tracks).map(([key, track]) => {
            if(key !== "NULL") {
                console.log({track});
                
                list.push({
                    title: track.name,
                    value: track.id,
                    filename: track.filename
                })
            }
        })
        return list.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
    }, [tracks])


    
    const hToggle = ({value}) => {
        const track = tracks[value]
        console.log({track});
        
        
        
        setSelected(value)
        if(isFunc(onChange)) {
            onChange({
                stateKey,
                side,
                position,
                value,
                filename: track.filename
            })
        }
    }


    className = cl(
        className,
        "SettingsObjectScheduleChangeTrack"
    )

    return(
        <div className={className}>

            <div className="SettingsObjectScheduleChangeTrack__select">
                <Select
                    label={label}
                    placeholder="Выбрать объявление"
                    options={tracksList}
                    selected={selected}
                    onChange={hToggle}
                    search={true}
                    searchWord={searchWord}
                    resetMultiple={{
                        title: "Убрать",
                        value: "",
                    }}
                />
            </div>

            <div className="SettingsObjectScheduleChangeTrack__buttons spp-btns">
                <ButtonCircle
                    size="md"
                    onClick={() => openModal({
                        type: "advertCreate",
                        zIndex: 1,
                    })}
                >
                    <SvgPlus/>
                </ButtonCircle>

                <ButtonCircle
                    size="md"
                    onClick={deleteTrack}
                    disabled={deletingAdvert || isNaN(selected)}
                >
                    <SvgTrash/>
                </ButtonCircle>
            </div>
            
        </div>
    )

    function deleteTrack() {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: "Вы точно хотите удалить данное объявление?",
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteAdvert({ advertId: selected })
                            setSelected("NULL")
                            closeModal("confirm")
                        }
                    },
                    download: {
                        title: "скачать",
                        onClick: () => {
                            deleteAdvert({ advertId: selected })
                            setSelected("NULL")
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }
}

const stateToProps = (state, props) => {
    const tracks        = state.settings.settings[OBJECT_SCHEDULE].tracks || {}
    const selectedItems = state.settings.settings[OBJECT_SCHEDULE][props.stateKey]?.selected || []
    const selectedItem  = selectedItems[0] || props.itemId

    const itemStateKey  = generateStateKey([ props.stateKey, selectedItem ])
    const itemSettings  = state.settings.settings[OBJECT_SCHEDULE][itemStateKey] || {}

    let selectedTrack = itemSettings[`${props.position}_id`][props.side]

    return {
        tracks,
        selectedTrack,
        deletingAdvert: state.modal.types.advertCreate.deleting,
    }
}

const actionsToProps = {
    deleteAdvert,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ChangeTrack)