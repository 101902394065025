import React from 'react'

import ScrollContainer from '../../ScrollContainer'
import Spinner from '../../Spinner'
import VideolistItem from '../VideolistItem'
import Empty from '../Empty'
import List from '../../List'



const Left = ({
    searchActive,
    searchFounded = [],
    videolists = [],
    loading,
    toggleSelected,
}) => {

    if (searchActive) videolists = searchFounded
    const getItems = () => {
        return videolists.map((videolist, index) => ({
            //return (
            id: videolist.id,
            name: videolist.name,
            element: < VideolistItem
                key={ videolist.id }
                id={ videolist.id }
                used={videolist.used }
                name={ videolist.name }
                totaltime={ videolist.totaltime }
                totalsize={ videolist.totalsize2 }
                totalcount={ videolist.totalcount }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }
    return (
        <section className="">
            <div onClick={ toggleSelected }>
                { loading
                    ? <Spinner className="pt-xs pb-xs" />

                    // : (videolists.length || searchActive)
                    // ?
                    :
                    <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        // variant={ "none" }
                        content={ { items: getItems(), variant: "none" } }
                    />

                    // : <Empty title="Видеолистов пока нет." />
                }

            </div>
        </section>
    )
}



export default Left