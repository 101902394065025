export const DELETE_PUBLIC_PLAYLISTS = "PublicPlaylists/DELETE_PUBLIC_PLAYLISTS"
export const LOAD_PUBLIC_PLAYLISTS = "PublicPlaylists/LOAD_PUBLIC_PLAYLISTS"
export const LOAD_ALL_PUBLIC_SONGS = "PublicPlaylists/LOAD_ALL_PUBLIC_SONGS"

export const LOADING = "PublicPlaylists/LOADING"
export const LOADING_ASSOC = "PublicPlaylists/LOADING_ASSOC"
export const PUT_PUBLIC_PLAYLISTS = "PublicPlaylists/PUT_PUBLIC_PLAYLISTS"
export const PUT_ALL_PUBLIC_TRACKS = "PublicPlaylists/PUT_ALL_PUBLIC_TRACKS"
export const PUT_ALL_PUBLIC_TRACKS_FILTER_NAME = "PublicPlaylists/PUT_ALL_PUBLIC_TRACKS_FILTER_NAME"

export const TOGGLE_SELECTED_PUBLIC_PLAYLIST = "PublicPlaylists/TOGGLE_SELECTED_PUBLIC_PLAYLIST"
export const LOAD_ASSOC_PUBLIC_TRACKS = "PublicPlaylists/LOAD_ASSOC_PUBLIC_TRACKS"
export const PUT_ASSOC_PUBLIC_TRACKS = "PublicPlaylists/PUT_ASSOC_PUBLIC_TRACKS"
export const PUT_ASSOC_PUBLIC_TRACKS_TABLE = "PublicPlaylists/PUT_ASSOC_PUBLIC_TRACKS_TABLE"
export const PUT_ASSOC_MOBILE_PUBLIC_TRACKS = "PublicPlaylists/PUT_ASSOC_MOBILE_PUBLIC_TRACKS"
export const TOGGLE_SELECTED_PUBLIC_SONG = "PublicPlaylists/TOGGLE_SELECTED_PUBLIC_SONG"
export const SORT_PUBLIC_PLAYLISTS = "PublicPlaylists/SORT_PUBLIC_PLAYLISTS"
export const SORT_PUBLIC_SONGS = "PublicPlaylists/SORT_PUBLIC_SONGS"
export const SELECT_SONGS_ALL = "PublicPlaylists/SELECT_SONGS_ALL"
export const SET_SELECT_SONGS_ALL = "PublicPlaylists/SET_SELECT_SONGS_ALL"

export const UPDATE_PUBLIC_PLAYLIST = "PublicPlaylists/UPDATE_PUBLIC_PLAYLIST"


export const updatePublicPlaylistItem = payload => ({
    type: UPDATE_PUBLIC_PLAYLIST,
    payload
})

export const loadPublicPlaylists = () => ({
    type: LOAD_PUBLIC_PLAYLISTS,
})

export const loadAllPublicSongs = payload => ({
    type: LOAD_ALL_PUBLIC_SONGS,
    payload
})


export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const loading_assoc = mode => ({
    type: LOADING_ASSOC,
    payload: mode
})

export const deletePublicPlaylists = selectedPublicPlaylists => ({
    type: DELETE_PUBLIC_PLAYLISTS,
    payload: selectedPublicPlaylists
})
export const putAssocPublicTracks = payload => ({
    type: PUT_ASSOC_PUBLIC_TRACKS,
    payload
})
//сортировка и фильтрация
export const putAssocPublicTracksTable = data => ({
    type: PUT_ASSOC_PUBLIC_TRACKS_TABLE,
    payload: data
})

export const putAssocMobilePublicTracks = assoc_public_tracks => ({
    type: PUT_ASSOC_MOBILE_PUBLIC_TRACKS,
    payload: assoc_public_tracks
})

export const putPublicPlaylists = public_playlists => ({
    type: PUT_PUBLIC_PLAYLISTS,
    payload: public_playlists
})

export const putAllPublicTracks = payload => ({
    type: PUT_ALL_PUBLIC_TRACKS,
    payload
})

export const putAllPublicTracksFilterName = payload => ({
    type: PUT_ALL_PUBLIC_TRACKS_FILTER_NAME,
    payload
})

export const toggleSelectedPublicPlaylist = ({ id, selected, simple, required }) => ({
    type: TOGGLE_SELECTED_PUBLIC_PLAYLIST,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})
export const loadAssocPublicTracks = data => ({
    type: LOAD_ASSOC_PUBLIC_TRACKS,
    payload: data
})
export const toggleSelectedPublicSong = ({ id_song, selected_song, onlyOne }) => ({
    type: TOGGLE_SELECTED_PUBLIC_SONG,
    payload: {
        id_song,
        selected_song,
        onlyOne
    }
})
export const sortPublicPlaylists = sortSource => ({
    type: SORT_PUBLIC_PLAYLISTS,
    payload: sortSource
})
export const sortPublicSongs = sortSource => ({
    type: SORT_PUBLIC_SONGS,
    payload: sortSource
})
export const selectSongsAll = () => ({
    type: SELECT_SONGS_ALL,
    payload: {}
})
export const setSelectSongsAll = (ids) => ({
    type: SET_SELECT_SONGS_ALL,
    payload: ids
})
