import React, { useState, useEffect, useRef } from "react";

const debounceTime = 700

const RangeSliderWithPrice = ({ min, max, step, initialFrom, initialTo, onChange }) => {

    const [from, setFrom] = useState(initialFrom || min);
    const [to, setTo] = useState(initialTo || max);
    const [inputFrom, setInputFrom] = useState(from);
    const [inputTo, setInputTo] = useState(to);
    const [dragging, setDragging] = useState(null);




    // Проверка inputFrom через 300 мс
    useEffect(() => {
        const timeout = setTimeout(() => {
            const parsedFrom = parseFloat(inputFrom);
            if (isNaN(parsedFrom) || parsedFrom < min) {
                setFrom(min);
                setInputFrom(min.toString());
            } else if (parsedFrom > to) {
                setFrom(to);
                setInputFrom(to.toString());
            } else {
                setFrom(parsedFrom);
            }
        }, debounceTime);

        return () => clearTimeout(timeout);
    }, [inputFrom, min, to]);

    // Проверка inputTo через 300 мс
    useEffect(() => {
        const timeout = setTimeout(() => {
            const parsedTo = parseFloat(inputTo);
            if (isNaN(parsedTo) || parsedTo > max) {
                setTo(max);
                setInputTo(max.toString());
            } else if (parsedTo < from) {
                setTo(from);
                setInputTo(from.toString());
            } else {
                setTo(parsedTo);
            }
        }, debounceTime);

        return () => clearTimeout(timeout);
    }, [inputTo, max, from]);


    useEffect(() => {
        if (!dragging)
            onChange({ from, to })
    }, [from, to, dragging])


    const sliderRef = useRef(null);

    const calculateValue = (clientX) => {
        const slider = sliderRef.current.getBoundingClientRect();
        const sliderWidth = slider.width;
        const offsetX = Math.min(Math.max(clientX - slider.left, 0), sliderWidth); // Ограничиваем движение
        const value = Math.round((offsetX / sliderWidth) * (max - min) + min);
        return Math.min(Math.max(value, min), max); // Возвращаем значение в пределах [min, max]
    };

    const handleMouseDown = (type) => {
        setDragging(type);
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;

        const value = calculateValue(e.clientX);

        if (dragging === "from" && value <= to - step) {
            setFrom(value);
            setInputFrom(value)
        } else if (dragging === "to" && value >= from + step) {
            setTo(value);
            setInputTo(value)
        }
    };

    useEffect(() => {
        const handleMouseMoveGlobal = (e) => {
            if (!dragging) return;

            const value = calculateValue(e.clientX);

            if (dragging === "from" && value <= to - step) {
                setFrom(value);
                setInputFrom(value);
            } else if (dragging === "to" && value >= from + step) {
                setTo(value);
                setInputTo(value);
            }
        };

        const handleMouseUpGlobal = () => {
            if (dragging) {
                setDragging(null);
            }
        };

        document.addEventListener("mousemove", handleMouseMoveGlobal);
        document.addEventListener("mouseup", handleMouseUpGlobal);

        return () => {
            document.removeEventListener("mousemove", handleMouseMoveGlobal);
            document.removeEventListener("mouseup", handleMouseUpGlobal);
        };
    }, [dragging, from, to, step]);




    const handleMouseUp = () => {
        setDragging(null);
    };

    const calculatePercentage = (value) => {
        return ((value - min) / (max - min)) * 100;
    };

    const sliderStyles = {
        container: {
            position: "relative",
            width: "100%",
            // height: "50px",
        },
        inputStyle: {
            width: 80,
            position: 'relative',
            zIndex: 2,
            boxShadow: 'none',
            height: 'auto',
            fontWeight: 500,
            fontSize: '0.813rem',
            fontFamily: `"Rubik", -apple-system, "Circe", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
            borderRadius: 0,
            padding: '8px 0',
            backgroundColor: 'transparent',
            lineHeight: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: '2px',
            borderBottomColor: 'rgba(10, 10, 10, 0.72);',
            color: 'rgba(10, 10, 10, 0.72)',
            outline: 'none',
        },
        slider: {
            position: "relative",
            width: "100%",
            height: "5px",
            background: "#ddd",
            borderRadius: "5px",
        },
        range: {
            position: "absolute",
            height: "5px",
            background: "#0a0a0a70",
            borderRadius: "5px",
            top: "0",
            left: `${calculatePercentage(from)}%`,
            right: `${100 - calculatePercentage(to)}%`,
        },
        thumb: {
            position: "absolute",
            width: "20px",
            height: "20px",
            background: "#fff",
            border: "2px solid #0a0a0a70",
            borderRadius: "50%",
            top: "-8px",
            transform: "translate(-50%, 0)",
            cursor: "pointer",
        },
        label: {
            position: "absolute",
            top: "-30px",
            background: "#0a0a0a70",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "12px",
            transform: "translate(-50%, 0)",
        },
    };

    return (
        <div
            style={sliderStyles.container}
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        // onMouseLeave={handleMouseUp}
        >
            <div style={{ marginLeft: '-16px', display: 'flex', flexDirection: 'row', gap: 20, paddingBottom: 50, alignSelf: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'flex-end' }}>
                    <label class="form-label">от</label>
                    <input
                        style={sliderStyles.inputStyle}
                        value={inputFrom}
                        onChange={e => setInputFrom(e.target.value)}
                    />
                </div>
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'flex-end' }}>
                    <label class="form-label">до</label>
                    <input
                        style={sliderStyles.inputStyle}
                        value={inputTo}
                        onChange={e => setInputTo(e.target.value)}
                    />
                </div>
            </div>
            {/* Слайдер */}
            <div ref={sliderRef} style={sliderStyles.slider}>
                {/* Диапазон */}
                <div style={sliderStyles.range}></div>

                {/* Ползунок "from" */}
                <div
                    style={{
                        ...sliderStyles.thumb,
                        left: `${calculatePercentage(from)}%`,
                    }}
                    onMouseDown={() => handleMouseDown("from")}
                >
                    <span style={{ ...sliderStyles.label, left: "50%" }}>{from}</span>
                </div>

                {/* Ползунок "to" */}
                <div
                    style={{
                        ...sliderStyles.thumb,
                        left: `${calculatePercentage(to)}%`,
                    }}
                    onMouseDown={() => handleMouseDown("to")}
                >
                    <span style={{ ...sliderStyles.label, left: "50%" }}>{to}</span>
                </div>
            </div>
        </div >
    );
};

export default RangeSliderWithPrice;
