import { DO_LOGOUT } from '../Login/actions'
import {
    LOAD_PUBLIC_PLAYLISTS,
    PUT_ASSOC_MOBILE_PUBLIC_TRACKS,
    SORT_PUBLIC_SONGS,
    LOADING,
    LOADING_ASSOC,
    SORT_PUBLIC_PLAYLISTS,
    PUT_PUBLIC_PLAYLISTS,
    TOGGLE_SELECTED_PUBLIC_PLAYLIST,
    TOGGLE_SELECTED_PUBLIC_SONG,
    LOAD_ASSOC_PUBLIC_TRACKS,
    PUT_ASSOC_PUBLIC_TRACKS, PUT_ASSOC_PUBLIC_TRACKS_TABLE, SET_SELECT_SONGS_ALL,
    UPDATE_PUBLIC_PLAYLIST,
    PUT_ALL_PUBLIC_TRACKS,
    PUT_ALL_PUBLIC_TRACKS_FILTER_NAME,
    LOAD_ALL_PUBLIC_SONGS,
    PUT_SEARCH_NAME
} from './actions'

const emptyMap = new Map();

const inititalState = {
    loading: false,
    loading_assoc: false,
    loading_all: false,
    search_name: '',
    allPublicPlaylists: [],
    selected: [],
    assocPublicTracks: emptyMap,
    allPublicTracks: emptyMap,
    allPublicFilterName: '',
    assocPublicTracksTable: {
        sort_field: '',
        sort_dir: '',
        filter: {}//ключи - названия полей, внутри массив значений например genre_name[]: Funk
        //genre_name[]: Hip-Hop
        //songauthor_name[]: Спицын Глеб Игоревич
        //tempo[]: 50
    },
    selected_song: [],
    selected_song_all: []
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case UPDATE_PUBLIC_PLAYLIST:
            console.log({ payload });

            return {
                ...state,
                allPublicPlaylists: state.allPublicPlaylists.map(item => item.id === payload.id || item.id === payload.id_playlist ? payload : item)
            }

        case SORT_PUBLIC_SONGS:
            if (payload.sort_type === "name") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.name < b.name) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.name > b.name) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            } else if (payload.sort_type === "duration") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.cliptime < b.cliptime) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.cliptime > b.cliptime) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            } else if (payload.sort_type === "temp") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.tempo < b.tempo) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.tempo > b.tempo) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            } else if (payload.sort_type === "genre") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.genre_name < b.genre_name) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.genre_name > b.genre_name) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            } else if (payload.sort_type === "author") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.songauthor_name < b.songauthor_name) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.songauthor_name > b.songauthor_name) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            } else if (payload.sort_type === "added") {
                let songs = Object.entries(state.assocPublicTracks).sort(([, a], [, b]) => {
                    if (a.year < b.year) {
                        return payload.direction === "up" ? -1 : 1
                    }
                    if (a.year > b.year) {
                        return payload.direction === "up" ? 1 : -1
                    }
                    return 0
                })
                let obj = {}
                songs = songs.map(item => {
                    return item[1]
                })
                for (let i = 0; i < songs.length; i++) {
                    obj[Symbol(songs[i].id).toString()] = songs[i]
                }
                return {
                    ...state,
                    assocPublicTracks: { ...obj }
                }
            }
        case SORT_PUBLIC_PLAYLISTS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allPublicPlaylists:
                        Array.from(state.allPublicPlaylists).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "songs") {
                return {
                    ...state,
                    allPublicPlaylists:
                        Array.from(state.allPublicPlaylists).sort((a, b) => {

                            if (a.totalcount < b.totalcount) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalcount > b.totalcount) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "size") {
                return {
                    ...state,
                    allPublicPlaylists:
                        Array.from(state.allPublicPlaylists).sort((a, b) => {
                            if (a.totalsize < b.totalsize) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.totalsize > b.totalsize) {
                                return payload.direction === "up" ? 1 : -1
                            } else {
                                return 0
                            }
                        }),
                }
            } else if (payload.sort_type === "duration") {
                return {
                    ...state,
                    allPublicPlaylists:
                        Array.from(state.allPublicPlaylists).sort((a, b) => {
                            let atot = Number(a.totaltime.split(":")[0]) * 3600 + Number(a.totaltime.split(":")[1]) * 60 + Number(a.totaltime.split(":")[2])
                            let btot = Number(b.totaltime.split(":")[0]) * 3600 + Number(b.totaltime.split(":")[1]) * 60 + Number(b.totaltime.split(":")[2])
                            if (atot < btot) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (atot > btot) {
                                return payload.direction === "up" ? 1 : -1
                            } else {
                                return 0
                            }
                        }),
                }
            }
        case LOAD_PUBLIC_PLAYLISTS:
            return {
                ...state,
                allPublicPlaylists: [],
                selected: [...state.selected]
            }
        case PUT_PUBLIC_PLAYLISTS:
            return {
                ...state,
                allPublicPlaylists: payload.public_playlists,
                selected_song_all: [],
                selected_song: []
                //tracksAssoc: payload.tracksAssoc,
            }
        case PUT_ALL_PUBLIC_TRACKS:
            return {
                ...state,
                allPublicTracks: payload,
                // payload?.size ? payload : state.allPublicTracks,
                loading_all: false
                //tracksAssoc: payload.tracksAssoc,
            }
        case LOAD_ALL_PUBLIC_SONGS:
            return { ...state, loading_all: true };

        case PUT_ALL_PUBLIC_TRACKS_FILTER_NAME:
            return {
                ...state,
                allPublicFilterName: payload
                //tracksAssoc: payload.tracksAssoc,
            }
        case PUT_ASSOC_PUBLIC_TRACKS:
            return {
                ...state,
                assocPublicTracks: payload.assoc_public_tracks,
                selected_song: [],
                selected_song_all: [],
            }
        case PUT_ASSOC_PUBLIC_TRACKS_TABLE:
            return {
                ...state,
                assocPublicTracksTable: {
                    ...state.assocPublicTracksTable,
                    ...payload,
                    sort_dir: (payload.sort_field && payload.sort_field === state.assocPublicTracksTable.sort_field && state.assocPublicTracksTable.sort_dir === "ASC") ? "DESC" : "ASC"
                }
            }
        case PUT_ASSOC_MOBILE_PUBLIC_TRACKS:
            return {
                ...state,
                allPublicPlaylists:
                    state.allPublicPlaylists.map(pl => {
                        if (pl.id === payload.current) {
                            pl["songs"] = payload.assoc_public_tracks
                        }
                        return pl

                    })
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case LOADING_ASSOC:
            return {
                ...state,
                loading_assoc: payload
            }
        case LOAD_ASSOC_PUBLIC_TRACKS:
            return {
                ...state,
                //assocPublicTracks: { ...state.assocPublicTracks },
                selected: [...state.selected]
            }
        case SET_SELECT_SONGS_ALL:
            return {
                ...state,
                //assocPublicTracks: { ...state.assocPublicTracks },
                selected_song_all: [...payload],
                selected_song: [...payload]
            }
        case TOGGLE_SELECTED_PUBLIC_PLAYLIST:
            const { id, selected, simple, required } = payload
            if (simple === true) {
                return {
                    ...state,
                    selected,
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(playlistId => playlistId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(playlistId => playlistId !== id)
                }
            }
        case TOGGLE_SELECTED_PUBLIC_SONG:
            const { id_song, selected_song, onlyOne } = payload
            if (selected_song === true) {
                return {
                    ...state,
                    selected_song:
                        (state.selected_song.indexOf(id_song) === -1)
                            ? state.selected_song.concat([id_song])
                            : state.selected_song
                }
            } else if (selected_song === false) {
                return {
                    ...state,
                    selected_song:
                        (state.selected_song.indexOf(id_song) !== -1)
                            ? state.selected_song.filter(objectId => objectId !== id_song)
                            : state.selected_song
                }
            } else {
                if (onlyOne === true) {
                    return {
                        ...state,
                        selected_song:
                            (state.selected_song.indexOf(id_song) === -1)
                                ? [id_song]
                                : []
                    }
                } else {
                    return {
                        ...state,
                        selected_song:
                            (state.selected_song.indexOf(id_song) === -1)
                                ? state.selected_song.concat([id_song])
                                : state.selected_song.filter(objectId => objectId !== id_song)
                    }
                }


            }


        case DO_LOGOUT:
            return inititalState;
    }
    return state
}

export default reducer