import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button as ButtonBS } from 'react-bootstrap';
import Spinner from '../../Spinner';
import './rangeSlyder.scss';
import SearchSelect from '../../SearchSelect';
import {
    loadPublicFilter,
    putPublicFilterParams,
    updatePublicFilterParams
} from '../../../store/Modal/types/PublicSongsFilter/actions';

import Header from '../components/header';
import { deepCompare, deepCreate } from '../../../utilities/deepCreate';
import { closeModal } from '../../../store/Modal/actions';
import { isEqual } from 'lodash';
import RangeSliderWithPrice from '../../RangeSlider';

const PublicAllSongsFilter = () => {
    const dispatch = useDispatch();

    // Состояние компонента
    const [filterParams, setFilterParams] = useState({});


    const loading = useSelector((state) => state.modalPublicSongsFilter.loading_all);


    const publicFilter = useSelector((state) => state.modalPublicSongsFilter.public_filter);
    const initialParams = useSelector((state) =>        state.modalPublicSongsFilter.public_filter_params_initial);
    const filterParamsAccepted = useSelector((state) => state.modalPublicSongsFilter.public_filter_params);

    const isInitial = deepCompare(filterParamsAccepted, initialParams);

    const isAccepted = deepCompare(filterParams, filterParamsAccepted);


    useEffect(() => {
        if (!publicFilter?.genre_name)
            dispatch(loadPublicFilter());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && !filterParams.tempo) {
            setFilterParams(deepCreate(filterParamsAccepted));
        }
    }, [loading, initialParams]);


    if (loading || !filterParams.genre_name) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Spinner />
                <span style={{ textAlign: 'center', marginTop: '10px' }}>
                    <h5>Загружаем параметры фильтра...</h5>
                </span>
            </div>
        )
    }

    const handleClose = () => {
        dispatch(closeModal('publicAllSongsFilter'));
    };

    const handleClear = () => {
        handleClose();
        if (!isInitial)
            dispatch(updatePublicFilterParams(deepCreate(initialParams)))


    };



    const handleSubmit = () => {
        handleClose();
        const params = deepCreate(filterParams);

        dispatch(updatePublicFilterParams(params));
    };

    const handleSelect = (data) => {
        setFilterParams((prev) => {
            const updated = { ...prev };
            if (!updated[data.type]) {
                updated[data.type] = [];
            }
            if (!updated[data.type].includes(data.value)) {
                updated[data.type].push(data.value);
            }
            return { ...updated };
        });
    };

    const handleDelete = (data) => {
        setFilterParams((prev) => {
            const updated = { ...prev };
            updated[data.type] = updated[data.type].filter((el) => el !== data.value.name);
            return updated;
        });
    };


    return (
        <div div className="ModalOneInput" >
            <Header title="Фильтр всех публичных треков" toggleOpened={handleClose} />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div id="dropdown-custom-components">
                    <h4>Темп</h4>
                </div>
            </div>

            <div style={{ marginBottom: '20px', gap: 200, width: '100%' }}>
                <div
                    style={{
                        marginLeft: 100,
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 20,
                        alignItems: 'flex-end',
                        height: '40px'
                    }}
                >
                    {!!publicFilter.tempo && (
                        <RangeSliderWithPrice
                            min={publicFilter.tempo[0] || 0}
                            max={publicFilter.tempo[publicFilter.tempo.length - 1] || 999}
                            step={5}
                            initialFrom={filterParams.tempo.from}
                            initialTo={filterParams.tempo.to}
                            onChange={(values) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    tempo: values
                                }))
                            }
                        />
                    )}
                </div>
            </div>

            <SearchSelect
                items={publicFilter.genre_name || []}
                title="Жанры"
                type="genre_name"
                placeholder="Введите название жанра..."
                params={filterParams}
                hdelete={handleDelete}
                select={handleSelect}
            />

            <div
                className="Modal__footer"
                style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
            >
                <ButtonBS onClick={handleSubmit} variant="primary" disabled={isAccepted || loading}>
                    {loading ? 'Поиск...' : 'Применить'}
                </ButtonBS>

                <ButtonBS onClick={handleClear} variant="dark" disabled={loading}>
                    {isInitial ? 'Закрыть' : 'Сбросить'}
                </ButtonBS>
            </div>
        </div>
    )
};

export default PublicAllSongsFilter;
