export default function({
    settings = {},
    name,
    disabled,
    onChange,
    additionalListener
}) {

    return [
        {
            type: "Switch",
            data: {
                disabled,
                value: !!settings?.[name],
                onChange,
                additionalListener
            }
        }
    ]
}