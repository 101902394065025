import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button as ButtonBS } from 'react-bootstrap';
import Spinner from '../../Spinner';
import SearchSelect from '../../SearchSelect';
import Header from '../components/header';
import {
    loadRubrics,
    updateFilterParams
} from '../../../store/Modal/types/PublicSongsFilter/actions';
import { closeModal } from '../../../store/Modal/actions';
import { deepCreate, deepCompare } from '../../../utilities/deepCreate';
import RangeSliderWithPrice from '../../RangeSlider';



const PublicSongsFilter = () => {
    const dispatch = useDispatch();

    // Redux state
    const loading = useSelector((state) => state.modalPublicSongsFilter.loading);
    const rubrics = useSelector((state) => state.modalPublicSongsFilter.rubrics);
    const selectedPlaylist = useSelector((state) => state.public_playlists.selected[0]);
    const rubricsPlaylist = useSelector((state) => state.modalPublicSongsFilter.playlist);
    const filterParamsAccepted = useSelector((state) => state.modalPublicSongsFilter.filter_params);
    const initialParams = useSelector((state) => state.modalPublicSongsFilter.filter_params_initial);

    // Local state
    const [filterParams, setFilterParams] = useState({});


    const isInitial = deepCompare(filterParamsAccepted, initialParams);
    const isAccepted = deepCompare(filterParams, filterParamsAccepted);

    useEffect(() => {
        if (!rubrics || selectedPlaylist !== rubricsPlaylist) {
            dispatch(loadRubrics());
        }
    }, [dispatch, rubrics, selectedPlaylist, rubricsPlaylist]);

    useEffect(() => {
        if (!loading && !filterParams.tempo) {
            setFilterParams(deepCreate(filterParamsAccepted));
        }
    }, [loading, initialParams]);


    useEffect(() => {
        if (!loading) {
            setFilterParams(deepCreate(filterParamsAccepted));
        }
    }, [loading, filterParamsAccepted]);

    const handleClose = () => {
        dispatch(closeModal('publicsongsFilter'));
    };

    const handleClear = () => {
        handleClose();
        setFilterParams(deepCreate(initialParams));
        if (!isInitial) {
            dispatch(updateFilterParams(initialParams));
        }
    };

    const handleSubmit = () => {
        handleClose();
        dispatch(updateFilterParams(deepCreate(filterParams)));
    };

    const handleSelect = (data) => {
        setFilterParams((prev) => {
            const updated = { ...prev };
            if (!updated[data.type]) {
                updated[data.type] = [];
            }
            if (!updated[data.type].includes(data.value)) {
                updated[data.type].push(data.value);
            }
            return updated;
        });
    };

    const handleDelete = (data) => {
        setFilterParams((prev) => {
            const updated = { ...prev };
            updated[data.type] = updated[data.type].filter((el) => el !== data.value.name);
            return updated;
        });
    };

    console.log({
        filterParams,
        rubrics,
        initialParams,
        filterParamsAccepted
    });


    if (loading || !filterParams.tempo) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Spinner />
                <span style={{ textAlign: 'center', marginTop: '10px' }}>
                    <h5>Загружаем параметры фильтра...</h5>
                </span>
            </div>
        );
    }

    return (
        <div className="ModalOneInput">
            <Header
                title="Фильтр публичных треков"
                toggleOpened={handleClose}
            />



            <div hidden style={{ marginTop: '20px' }}>
                <SearchSelect
                    items={rubrics.songauthor_name || []}
                    title="Авторы"
                    type="songauthor_name"
                    placeholder="Введите имя автора..."
                    params={filterParams}
                    hdelete={handleDelete}
                    select={handleSelect}
                />
            </div>


            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div id="dropdown-custom-components">
                    <h4>Темп</h4>
                </div>
            </div>

            <div style={{ marginBottom: '20px', gap: 200, width: '100%' }}>
                <div
                    style={{
                        marginLeft: 100,
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 20,
                        alignItems: 'flex-end',
                        height: '40px'
                    }}
                >
                    {!!rubrics.tempo && (
                        <RangeSliderWithPrice
                            min={rubrics.tempo[0]}
                            max={rubrics.tempo[rubrics.tempo.length - 1]}
                            step={5}
                            initialFrom={filterParams.tempo.from}
                            initialTo={filterParams.tempo.to}
                            onChange={(values) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    tempo: values
                                }))
                            }
                        />
                    )}
                </div>
            </div>

            <SearchSelect
                items={rubrics.genre_name || []}
                title="Жанры"
                type="genre_name"
                placeholder="Введите название жанра..."
                params={filterParams}
                hdelete={handleDelete}
                select={handleSelect}
            />
            <div
                className="Modal__footer"
                style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
            >
                <ButtonBS onClick={handleSubmit} variant="primary" disabled={isAccepted || loading}>
                    {loading ? 'Поиск...' : 'Применить'}
                </ButtonBS>

                <ButtonBS onClick={handleClear} variant="dark" disabled={loading}>
                    {isInitial ? 'Закрыть' : 'Сбросить'}
                </ButtonBS>
            </div>
        </div>
    );
};

export default PublicSongsFilter;
