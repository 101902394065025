import React, { useEffect } from 'react'
import {connect} from 'react-redux'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'
import Settings from './Settings'

import SlideMenu from '../../../../components/SlideMenu'

import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

// Вспомогательная функция для мемоизации массивов
const memoizeArray = fn => createSelector(fn, result => (Array.isArray(result) ? [...result] : result));

// Вспомогательная функция для глубокого сравнения
const deepMemoize = fn => {
    let lastValue = undefined;
    return createSelector(fn, result => {
        if (isEqual(lastValue, result)) {
            return lastValue;
        }
        lastValue = result;
        return result;
    });
};

// Селекторы
const getTracksThemeIds = state => state.tracks.tracksTheme.map(tr => tr.id);
const getSelectedTracks = state => state.tracks.selected;
const getSelectedObjects = state => state.objects.selected;
const getDisableWhereAndWhenBlock = state => state.advert_page.disableWhereAndWhenBlock;

// Мемоизированные селекторы
const selectSelectedThemeTracks = deepMemoize(state => {
    const selected = getSelectedTracks(state);
    const themesIds = getTracksThemeIds(state);
    return selected.filter(id => themesIds.includes(id));
});

const selectSelectedTracks = memoizeArray(getSelectedTracks);

const selectSelectedObjects = memoizeArray(getSelectedObjects);

const selectDisableWhereAndWhenBlock = createSelector(
    [getDisableWhereAndWhenBlock],
    disableWhereAndWhenBlock => disableWhereAndWhenBlock
);


const When = ({ selectedTracks, selectedObjects, selectedThemeTracks, disableWhereAndWhenBlock }) => {
    console.log("Update settings");

    useEffect(() => {
        console.log("Изменилось selectedTracks:", selectedTracks);
    }, [selectedTracks]);

    useEffect(() => {
        console.log("Изменилось selectedObjects:", selectedObjects);
    }, [selectedObjects]);

    useEffect(() => {
        console.log("Изменилось selectedThemeTracks:", selectedThemeTracks);
    }, [selectedThemeTracks]);

    useEffect(() => {
        console.log("Изменилось disableWhereAndWhenBlock:", disableWhereAndWhenBlock);
    }, [disableWhereAndWhenBlock]);
    
    
    return(
        <>
            {/* 3 */}

            <Place 
                number={3}
                className="AdvertWhen"
            >
                {(selectedTracks?.length && selectedObjects?.length && !(!!selectedThemeTracks && !!selectedThemeTracks.length && disableWhereAndWhenBlock))
                    ? <SlideMenu
                        activeItem={1}
                        items={[
                            { 
                                title: "Настройка ролика",
                                content: <Settings placeNumber={3} active/>
                            }
                        ]}
                    />
                    : <Empty>

                    </Empty>
                }
            </Place>
        </>
    )
}


const stateToProps = state => ({
    selectedThemeTracks: selectSelectedThemeTracks(state),
    selectedTracks: selectSelectedTracks(state),
    selectedObjects: selectSelectedObjects(state),
    disableWhereAndWhenBlock: selectDisableWhereAndWhenBlock(state),
});

export default connect(stateToProps, null)(When)