import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { savePeriod } from '../../../../store/Settings/actions/components/Periods'
import { openModal } from '../../../../store/Modal/actions'

import Datepicker from '../../../Form/Datepicker'
import TextReadOnly from '../../../Form/TextReadOnly'


const Edit = ({
    id,
    type,
    periods = {},
    savePeriod,
    saving,
}) => {

    const [period, setPeriod] = useState({ fromdate: "", todate: "" })

    const hChange = ({ from, to, ever }) => {
        setPeriod({
            fromdate: from,
            todate: to,
            ever
        })
    }

    console.log({ periods });

    const submit = () => savePeriod({ type, id, period })

    const completed = (period?.fromdate?.length > 2)

    return (
        <>
            <div className="SettingsPeriodModal__grid">
                <Datepicker
                    type={"between"}
                    label="Новый период"
                    durationInput={true}
                    variant="icon"
                    titles={{
                        main: "Период",
                        from: "Дата начала трансляции",
                        to: "Дата окончания трансляции",
                    }}
                    data={{
                        disabledType: "past",
                    }}
                    onChange={hChange}
                />
                <TextReadOnly
                    label="Выбранный период"
                    value={periods[id].title}
                    onClick={() => { }}
                />
            </div>

            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={submit} disabled={saving || !completed}>
                    {saving ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => ({
    saving: state.settings.periods[props.type]?.saving,
    periods: state.settings.periods[props.type]?.periodsAssoc,
})

const actionsToProps = {
    savePeriod,
    openModal,
}

export default connect(stateToProps, actionsToProps)(Edit)