import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { CREATE_OBJECTS_GROUP, loadObjectsGroup, toggleSelectedGroup } from '../../store/ObjectsGroup/actions'
import { showAlertError, showAlertSuccess } from '../../store/Alert/actions'
import axios from 'axios'
import { closeModal } from "../../store/Modal/actions";

export default function* () {
    yield takeEvery(CREATE_OBJECTS_GROUP, worker)
}

function* worker({ payload }) {
    //const { title } = payload

    try {
        const created = yield call(fetchCreate, payload)

        // Ошибка
        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_OBJECTS_GROUP}.`, created],
                text: created.error //"Группа объектов не была создана. Попробуйте повторить."
            }))
            yield put(closeModal("objectGroupCreate"))
            console.error(created.error)
            // Успех
        } else {
            yield put(showAlertSuccess({
                response: [`Ошибка сервера при ${CREATE_OBJECTS_GROUP}.`, created],
                text: "Группа объектов успешно создана."
            }))

            

            yield put(loadObjectsGroup({ newId:  Number(created.success) }))
            yield put(closeModal("objectGroupCreate"))

        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сервера при ${CREATE_OBJECTS_GROUP}.`, e],
            text: "Группа объектов не была создана. Попробуйте повторить."
        }))
        console.error(e)
    }
}

function fetchCreate({ name, copy_id, copy_clip, copy_music }) {
    let params = {
        name
    }
    if (copy_clip || copy_music) {
        params['copy[id]'] = copy_id
        params['copy[clip]'] = copy_clip
        params['copy[music]'] = copy_music
    }
    return axios.get("/msgroup/insert", { params })
        .then(response => response.data)
}