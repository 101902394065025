import React from 'react'
import outside from '../../utilities/outside'

import AlertError from '../Alert/Error'
import AlertSuccess from '../Alert/Success'
import AlertInfo from '../Alert/Info'

import Modal from '../Modal'
import Loading from "../App/Loading";


const OutsideElements = () => {
    return (
        <>
            { outside(
                <>
                    <Loading />
                    <AlertError />
                    <AlertSuccess />
                    <AlertInfo />
                    <Modal type="advertCreate" />
                    <Modal type="element" />
                    <Modal type="oneInput" />
                    <Modal type="manyInputs" />
                    <Modal type="castinfo" />
                    <Modal type="adsReport" />
                    <Modal type="adsCreate" />
                    <Modal type="adsCreateExtra" />
                    <Modal type="adsEdit" />
                    <Modal type="adsEditExtra" />
                    <Modal type="adsCopy" />
                    <Modal type="video" />
                    <Modal type="confirm" />
                    <Modal type="mediaplan" />
                    <Modal type="videoHistory" />
                    <Modal type="playlistCreate" />
                    <Modal type="objectGroupCreate" />
                    <Modal type="playlistEdit" />
                    <Modal type="videolistCreate" />
                    <Modal type="videolistEdit" />
                    <Modal type="videoCreate" />
                    <Modal type="videoEdit" />
                    <Modal type="publicsongsFilter" />
                    <Modal type="publicAllSongsFilter" />
                    <Modal type="mobileInfoObject" />
                    <Modal type="templateCreate" />
                    <Modal type="settingsRole" />
                    <Modal type="settingsRoleEdit" />
                    <Modal type="settingsClipEdit" />
                </>
            ) }
        </>
    )
}

export default OutsideElements