export const UPDATE = "Modal/VideolistCreate/UPDATE"
export const LOADING = "Modal/VideolistCreate/LOADING"
export const CREATE_VIDEOLIST = "Modal/VideolistCreate/CREATE_VIDEOLIST"
export const UPDATE_VIDEOLIST = "Modal/VideolistCreate/UPDATE_VIDEOLIST"
export const EDIT_VIDEOLIST = "Modal/VideolistCreate/EDIT_VIDEOLIST"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const createVideolist = videolistData => ({
    type: CREATE_VIDEOLIST,
    payload: videolistData
})

export const updateVideolist = payload => ({
    type: UPDATE_VIDEOLIST,
    payload
})
export const editVideolist = videolistData => ({
    type: EDIT_VIDEOLIST,
    payload: videolistData
})