import { takeEvery, call, put, all, select, takeLeading } from 'redux-saga/effects'
import { LOAD_PLAYLIST, loading, putPlaylists, putPlaylist, updatePlaylist } from '../../store/ContentPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { buildGETUrl } from "../../utilities/buildGETUrl";
import { changeValue } from '../../store/Search/actions';
import { loadAssocPublicTracks } from '../../store/PublicPlaylists/actions';

export default function* () {
    yield takeLeading(LOAD_PLAYLIST, worker)
}

function* worker({ payload }) {
    // yield put(loading(true))

    //console.log(payload)
    // const selectedPlaylists = yield select(state => state.playlists.allPlaylists.filter(playlist => payload.includes(playlist.id)));
    yield put(changeValue({ type: 'songs', value: ''}));
        

    for (const selectedPlaylist of payload) {
        // if (typeof selectedPlaylist.songs !== "undefined") {
        //yield put(updatePlaylist({playlist: selectedPlaylist}));
        // continue;
        // }

        try {
            const fetchedPlaylist = yield call(fetchSongs, selectedPlaylist)

            if (fetchedPlaylist.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${LOAD_PLAYLIST}.`, fetchedPlaylist],
                    text: "Треки не были загружены. Попробуйте перезагрузить страницу."
                }))
                //yield put(putPlaylists({playlists}))
            } else {
                const fpl = fetchedPlaylist.success
                Object.values(fpl.songs).forEach(song => {
                    song.filename = buildGETUrl("clip/play", { id: song.id }) //"/clip/play?" + new URLSearchParams({id: song.id});
                    //song.download = "https://a.ms-box.ru/clip/download?" + new URLSearchParams({id: song.id});
                });
                console.log({fpl});
                yield put(updatePlaylist({ playlist: fpl }))
            }
        } catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${LOAD_PLAYLIST}.`, e],
                text: "Треки не были загружены. Попробуйте перезагрузить страницу."
            }))
            //yield put(putPlaylists({playlists: []}))
        } finally {
            // yield put(loading(false))
        }
    }
}

function fetchSongs(playlistId) {
    return axios.get("/playlist/get", {
        params: { id: playlistId },
    }).then(response => response.data)
}