import React from 'react'


const Minus = ({ title = 'Исключить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 4.233 4.233">
                <title>{title}</title>
                <path d="M.14 1.975c-.188 0-.188.282 0 .282h3.95c.188 0 .188-.282 0-.282z" data-name="Group 4108" fill="#0a0a0a"/></svg>
        </i>
    )
}

export default Minus 