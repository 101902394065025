import { takeEvery, call, put, select } from 'redux-saga/effects'
import { loadTracks, toggleSelectedTrack } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { loadIndentObjects } from "../../store/Objects/actions";
import { loadClips } from "../../store/Settings/actions/components/Clips";


import { COPY_VIDEO } from '../../store/ContentAllVideolists/actions';

export default function* () {
    yield takeEvery(COPY_VIDEO, worker)
}

function* worker({ payload }) {
    // yield put(loading(true))
    console.log({ payload });
    if (!payload) {
        return;
    }
    const selectedTracks = payload.ids;


    try {
        //TODO TASK_24
        const response = yield call(fetchCopyVideo, selectedTracks)
        console.log({ response });

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${COPY_VIDEO}.`, response],
                text: "Ролики не добавлены. Попробуйте повторить."
            }))

        } else {

            const ids = Object.values(response.success).map(el => Number(el.lastInsertId))

            const callback = () => toggleSelectedTrack({ ids})

            yield put(loadTracks({ callback }))
            yield put(loadTracks({ theme: true, callback }))
            yield put(loadIndentObjects())
            yield put(loadClips({
                indentId: selectedTracks[0],
                objectIds: [payload.ids],
                required: true
            }))
            yield put(toggleSelectedTrack({
                ids 
            }))


        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${COPY_VIDEO}.`, e],
            text: "Ролики не добавлены. Попробуйте повторить."
        }))
    } finally {
        if (payload.callback && typeof payload.callback === 'function') {
            payload.callback()
        }

        // yield put(loading(false))
    }
}

function fetchCopyVideo(id) {
    return axios.get("/clip/copy", {
        params: {
            id
        }
    }).then(response => response.data)

    return axios(
        {
            url: `/clip/copy?id=${id}`,
            method: "GET"

        }
    )
        .then(response => response.data)
}