import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import {
    loading,
    loading_assoc,
    putPublicPlaylists,
    putAssocMobilePublicTracks,
    putAssocPublicTracks,
    LOAD_ASSOC_PUBLIC_TRACKS,
    setSelectSongsAll
} from '../../store/PublicPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { CLEAR_VALUE, changeValue } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(LOAD_ASSOC_PUBLIC_TRACKS, worker)
    yield takeEvery(CLEAR_VALUE, worker)
}

const emptyMap = new Map();


function* worker({ payload }) {

    

    try {
        let playlistIds = yield select(state => state.public_playlists.selected)
        let playlistIds2 = yield select(state => state.playlists.selected)
        playlistIds = playlistIds[0];
        if (payload?.current) {
            playlistIds = payload.current
        }

        if (!playlistIds) {
            console.log('RETURN RETURN RETURN RETURN RETURN RETURN RETURN')
            return
        }
        console.log(0, payload);


        if (payload?.offset === 0) {
            yield put(loading_assoc(true))
            yield put(putAssocPublicTracks({ assoc_public_tracks: emptyMap }))
        }
        console.log(0.1);

        const public_tracks = yield select(state => state.public_playlists.assocPublicTracks)
        const public_tracks_table = yield select(state => state.public_playlists.assocPublicTracksTable)
        const search_name = yield select(state => state.public_playlists.allPublicFilterName)

        const searchName = payload?.name || search_name || '';

        console.log({ searchName });

        const search_params = yield select(state => state.modalPublicSongsFilter.filter_params)
        console.log(1);

        if(!payload){
            payload = {}
        }

        if (payload?.offset != 0) {
            payload.offset = public_tracks?.size || payload.offset || 0
        }
        console.log(2);




        const assocPublicTracks = new Map();
        let params = !!searchName.length ?
            {
                ...search_params,
                playlist_id: playlistIds, offset: payload.offset, name: searchName
            } : {
                ...search_params,
                // id: playlistIds, offset: payload.offset || 0
                playlist_id: playlistIds, offset: payload.offset || 0
            }

        console.log({ search_params, playlistIds, params }, '>>>>>>>>>>>>>>>>>>');


        if (public_tracks_table.sort_field) {
            params[`sort[${[public_tracks_table.sort_field]}]`] = public_tracks_table.sort_dir;
        }
        if (public_tracks_table.filter && public_tracks_table.filter.blacklist) {
            params.blacklist = playlistIds2[0];
        }


        if (params.tempo?.to) {
            params.tempo = `${params.tempo.from}-${params.tempo.to}`

        }

        const fetchedAssocPublicTracks = yield call(fetchAssocPublicTracks, { ...params })



        if (fetchedAssocPublicTracks.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_ASSOC_PUBLIC_TRACKS}.`, fetchedAssocPublicTracks],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putAssocPublicTracks({ assoc_public_tracks: assocPublicTracks }))
            yield put(setSelectSongsAll([]))
        } else {
            const apt = fetchedAssocPublicTracks.success

            Object.values(apt).forEach(track => {
                assocPublicTracks.set(track.id, {
                    ...track,
                    filename: buildGETUrl("clip/play", { id: track.id })
                });
            });



            console.log(LOAD_ASSOC_PUBLIC_TRACKS, { assocPublicTracks, public_tracks });


            console.log({ assocPublicTracks, public_tracks });

            const uniqueKeys = new Set([
                ...assocPublicTracks.keys(),
                ...public_tracks.keys()
            ]);

            console.log('Количество уникальных ключей:', uniqueKeys.size);



            if (payload?.current) {
                yield put(putAssocMobilePublicTracks({ assoc_public_tracks: assocPublicTracks, current: payload.current }))
            } else {
                if (payload?.offset === 0) {
                    console.log("1", assocPublicTracks);

                    yield put(putAssocPublicTracks({ assoc_public_tracks: assocPublicTracks, type: 'set' }))
                } else {
                    console.log("2", new Map([...public_tracks, ...assocPublicTracks]));
                    yield put(putAssocPublicTracks({ assoc_public_tracks: new Map([...public_tracks, ...assocPublicTracks]), type: 'add' }))
                }


            }

        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ASSOC_PUBLIC_TRACKS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу." + e
        }))
        yield put(putAssocPublicTracks({ assoc_public_tracks: emptyMap }))
    }

    finally {
        yield put(loading_assoc(false))
    }
}

function fetchAssocPublicTracks(params) {
    // if (params.sort) {
    //     params
    // }
    const sortKeys = Object.keys(params).filter((key) => key.startsWith('sort['));
    console.log({ sortKeys });

    if (sortKeys.length > 0) {

        const sortKey = sortKeys[0];
        const sortValue = params[sortKey];


        params['sort[id]'] = sortValue


    }

    delete params['sort[child]']

    // if (!!params.offset || params.tempo) {
    //     params.playlist_id = params.id
    //     delete params.id
    // }


    // return axios.get(`/playlist/tracks/${!!params.offset || params.tempo || params.name ? "search" : "select"}`, {
        return axios.get(`/playlist/tracks/search`, {

        params: {
            limit: 50,
            ...params
            // // id: playlistId.id,
            // offset: params.offset,
            // sort[]
        }
    })
        .then(response => response.data)
}