import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { loading, putRubrics, UPDATE_FILTER_PARAMS } from '../../../store/Modal/types/PublicSongsFilter/actions'
import { loading_assoc } from '../../../store/PublicPlaylists/actions'
import { showAlertError } from '../../../store/Alert/actions'
import { putAssocPublicTracks } from '../../../store/PublicPlaylists/actions'
import { closeModal } from '../../../store/Modal/actions'
import axios from 'axios'
import { deepCreate } from '../../../utilities/deepCreate'

export default function* () {
    yield takeEvery(UPDATE_FILTER_PARAMS, worker)
}

function* worker({ payload }) {
    yield put(loading_assoc(true))
    console.log(UPDATE_FILTER_PARAMS, payload)
    const public_tracks_table = yield select(state => state.public_playlists.assocPublicTracksTable)

    const search_name = yield select(state => state.public_playlists.allPublicFilterName)


    console.log({search_name});
    
    
    let pay = { ...payload }//нельзя просто присваивать объекты, они работают по ссылке и все ниже не имеет смысла
    if (search_name?.length) {
        pay.name = search_name;
    }
    if (pay.tempo?.length === 0) {
        delete pay.tempo
    }
    if (pay.genre_name?.length === 0) {
        delete pay.genre_name
    }
    if (pay.songauthor_name?.length === 0) {
        delete pay.songauthor_name
    }
    if (public_tracks_table.sort_field) {
        pay[`sort[${[public_tracks_table.sort_field]}]`] = public_tracks_table.sort_dir;
    }
    try {
        const selected_list = yield select(state => state.public_playlists.selected[0])
        let data = deepCreate(pay)
        if (data.tempo?.to) {
            data.tempo = `${data.tempo.from}-${data.tempo.to}`

        }


        const fetchedFiltered = yield call(fetchFiltered, { playlist_id: selected_list, ...data })

        if (fetchedFiltered.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_FILTER_PARAMS}.`, fetchFiltered],
                text: "Параметры фильтра не применены. Попробуйте перезагрузить страницу."
            }))
            // yield put(putRubrics({ ...rubrics }))

        } else {
            yield put(closeModal("publicsongsFilter"))
            const apt = fetchedFiltered.success

            const assocPublicTracks = new Map()

            apt.forEach(track => {
                assocPublicTracks.set(track.id, {
                    ...track,
                    filename: buildGETUrl("clip/play", { id: track.id })
                });
            });

            yield put(putAssocPublicTracks({ assoc_public_tracks: assocPublicTracks }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_FILTER_PARAMS}.`, e],
            text: "Параметры фильтра не применены. Попробуйте перезагрузить страницу."
        }))
        //yield put(putRubrics({}))
    }

    finally {
        yield put(loading_assoc(false))
    }
}

function fetchFiltered(data) {
    return axios.get("/playlist/tracks/search", {
        params: {
            ...data
        }
    })
        .then(response => response.data)
}
