import React from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../../utilities/cl'


class Switch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: !!this.props?.value
        }

        this.fields = this.props.fields || {
            default: -1,
            first: {
                title: "Да",
                value: true,
            },
            second: {
                title: "Нет",
                value: false,
            }
        }

        this.hChange = this.hChange.bind(this)
        this.getDefaultFields = this.getDefaultFields.bind(this)
        this.updateValueFromProps = this.updateValueFromProps.bind(this)
    }

    componentDidMount() {
        this.fields = this.getDefaultFields(this.props.fields)
        this.updateValueFromProps()
    }

    componentDidUpdate(prevProps) {
        if (this.props.additionalListener)
            console.log(this.props.additionalListener.name, this.state.value, this.props.value,);

        if (
            // prevProps.value !== this.props.value &&
            this.props.value !== this.state.value) {
            this.updateValueFromProps()
        }
    }

    hChange(value) {
        this.setState(state => {


            value = (state.value === value) ? this.fields.default : value

            if (typeof this.props.onChange === "function") {

                this.props.onChange({
                    value,
                    name: this.props.name,
                })
            }

            if (this.props.additionalListener) {
                if (this.props.additionalListener.value)

                    this.props.additionalListener.onChange({
                        value: false,
                        name: this.props.additionalListener.name
                    })
            }

            return { value }
        })
    }

    render() {
        let {
            className = "",
            name,
            disabled
        } = this.props
        const {
            value,
        } = this.state

        const fields = this.fields

        className = cl(
            className,
            "FormSwitch"
        )

        return (
            <div className={className} name={name} style={{ opacity: disabled ? 0.5 : 1 }}>
                <div
                    className={`FormSwitch__button ${(value === fields.first.value) ? "active" : ""}`}
                    onClick={() => !disabled ? this.hChange(fields.first.value) : null}
                >
                    {fields.first.title}
                </div>
                <div
                    className={`FormSwitch__button ${(value === fields.second.value) ? "active" : ""}`}
                    onClick={() => !disabled ? this.hChange(fields.second.value) : null}
                >
                    {fields.second.title}
                </div>
            </div>
        )
    }

    getDefaultFields(fields = {}) {
        fields.default =
            (fields.default || fields.default === 0)
                ? fields.default
                : this.fields.default

        fields.first = fields.first || {}
        fields.first.title = fields.first.title || this.fields.first.title
        fields.first.value =
            (fields.first.value || fields.first.value === 0)
                ? fields.first.value
                : this.fields.first.value

        fields.second = fields.second || {}
        fields.second.title = fields.second.title || this.fields.second.title
        fields.second.value =
            (fields.second.value || fields.second.value === 0)
                ? fields.second.value
                : this.fields.second.value

        return fields
    }

    updateValueFromProps() {
        if (this.props.value !== undefined) {
            this.setState({
                value: this.props.value
            })
        }
    }
}

Switch.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    fields: PropTypes.object,
    onChange: PropTypes.func,
}

export default Switch