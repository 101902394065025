export default function({
    settings = {},
    name,
    onChange,
    additionalListener
}) {

    return [
        {
            type: "Switch",
            data: {
                value: settings[name],
                onChange,
                additionalListener
            }
        }
    ]
}