export const LOAD_RUBRICS = "Modal/PublicSongsFilter/LOAD_RUBRICS"
export const LOADING = "Modal/PublicSongsFilter/LOADING"
export const PUT_RUBRICS = "Modal/PublicSongsFilter/PUT_RUBRICS"
export const LOAD_PUBLIC_FILTER = "Modal/PublicSongsFilter/LOAD_PUBLIC_FILTER"
export const PUT_PUBLIC_FILTER = "Modal/PublicSongsFilter/PUT_PUBLIC_FILTER"
export const PUT_PUBLIC_FILTER_LOADING = "Modal/PublicSongsFilter/PUT_PUBLIC_FILTER_LOADING"
export const PUT_RUBRICS_ID = "Modal/PublicSongsFilter/PUT_RUBRICS_ID"
export const UPDATE_FILTER_PARAMS = "Modal/PublicSongsFilter/UPDATE_FILTER_PARAMS"
export const UPDATE_PUBLIC_FILTER_PARAMS = "Modal/PublicSongsFilter/UPDATE_PUBLIC_FILTER_PARAMS"
export const PUT_PUBLIC_FILTER_PARAMS = "Modal/PublicSongsFilter/PUT_PUBLIC_FILTER_PARAMS"

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const loadRubrics = () => ({
    type: LOAD_RUBRICS
})
export const putRubrics = rubrics => ({
    type: PUT_RUBRICS,
    payload: rubrics
})

export const loadPublicFilter = payload => ({
    type: LOAD_PUBLIC_FILTER,
    payload
})
export const purPublicFilter = payload => ({
    type: PUT_PUBLIC_FILTER,
    payload
})
export const putPublicFilterLoading = payload => ({
    type: PUT_PUBLIC_FILTER_LOADING,
    payload
})
export const updatePublicFilterParams = payload => ({
    type: UPDATE_PUBLIC_FILTER_PARAMS,
    payload
})
export const putPublicFilterParams = params => ({
    type: PUT_PUBLIC_FILTER_PARAMS,
    payload: params
})
export const putRubricsID = payload => ({
    type: PUT_RUBRICS_ID,
    payload
})
export const updateFilterParams = params => ({
    type: UPDATE_FILTER_PARAMS,
    payload: params
})
