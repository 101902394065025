import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_ALL_PUBLIC_SONGS, LOAD_PUBLIC_PLAYLISTS, loading, loading_assoc, putAllPublicTracks, putAllPublicTracksFilterName, putPublicPlaylists } from '../../store/PublicPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { UPDATE_PUBLIC_FILTER_PARAMS } from '../../store/Modal/types/PublicSongsFilter/actions'

export default function* () {
    yield takeEvery(LOAD_ALL_PUBLIC_SONGS, worker)
}
const limit = 50;
const emptyMap = new Map();

function* worker({ payload = {} }) {
    console.log(LOAD_ALL_PUBLIC_SONGS, { payload });
    if (!payload) {
        return
    }


    if (payload.offset === 0) {
        yield put(loading_assoc(true))
        yield put(putAllPublicTracks(emptyMap))
    }




    try {
        const public_tracks_table = yield select(state => state.public_playlists.assocPublicTracksTable)
        const public_tracks = !!!payload.offset ? emptyMap : yield select(state => state.public_playlists.allPublicTracks)
        const search_name = yield select(state => state.public_playlists.allPublicFilterName)
        const search_params = yield select(state => state.modalPublicSongsFilter.public_filter_params)

        if (payload.name  && search_name !== payload.name) {
            yield put(putAllPublicTracksFilterName(payload.name))
        }

        console.log({ search_name });


        const searchName = payload.name || search_name || '';
        console.log({ searchName }, payload.name);

        if (payload.offset != 0) {
            payload.offset = public_tracks?.size || payload.offset
        }


        const assocPublicTracks = new Map();



        const params = !!searchName.length ?
            {
                ...search_params,
                offset: payload?.offset || 0, name: searchName
            } : {
                ...search_params,
                offset: payload?.offset || 0
            }



        if (public_tracks_table.sort_field) {
            params[`sort[${[public_tracks_table.sort_field]}]`] = public_tracks_table.sort_dir;
        }


        const fetchedAssocPublicTracks = yield call(fetchAllPublicSongs, { ...params })
        console.log({ search_params: params }, fetchedAssocPublicTracks.success?.length || 'ups');


        if (fetchedAssocPublicTracks.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_ALL_PUBLIC_SONGS}.`, fetchedAssocPublicTracks],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
        } else {
            const apt = fetchedAssocPublicTracks.success;
            console.log(1);


            apt.forEach(track => {
                assocPublicTracks.set(track.id, {
                    ...track,
                    filename: buildGETUrl("clip/play", { id: track.id })
                });
            });


            // console.log({ assocPublicTracks, public_tracks });

            // const uniqueKeys = new Set([
            //     ...assocPublicTracks.keys(),
            //     ...public_tracks.keys()
            // ]);

            // console.log('Количество уникальных ключей:', uniqueKeys.size);


            if (payload?.offset === 0) {
                yield put(putAllPublicTracks(assocPublicTracks))
            } else {
                yield put(putAllPublicTracks(new Map([...public_tracks, ...assocPublicTracks])))
            }


        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ALL_PUBLIC_SONGS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу." + e
        }))
    }

    finally {
        yield put(loading_assoc(false))
    }
}

function fetchAllPublicSongs(params) {

    const sortKeys = Object.keys(params).filter((key) => key.startsWith('sort['));

    if (sortKeys.length > 0) {

        const sortKey = sortKeys[0];
        const sortValue = params[sortKey];

        if (params['sort[child]']) {
            params['sort[id]'] = params['sort[child]']
            delete params['sort[child]']

        } else {
            params['sort[id]'] = sortValue
        }
    }
    console.log({ params });


    if (params.tempo?.to) {
        params.tempo = `${params.tempo.from}-${params.tempo.to}`

    }


    console.log({ params });

    return axios.get("playlist/tracks/search", {
        params: {
            ...params,
            limit: 50
        }
    })
        // return fetch("http://a.ms-box.ru/playlist/select?public=true", { method: 'GET', credentials: "same-origin", mode: "no-cors", referrerPolicy: "unsafe-url", headers: { 'token': `${token}` } })
        .then(response => response.data)
}
