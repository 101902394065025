import React, { memo, useMemo, useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react'
import memoize from 'memoize-one';

import { cl } from '../../utilities/cl'
import { isFunc } from '../../utilities/isFunc'
import { carryMemoize } from '../../utilities/carryMemoize'

import ScrollContainer from '../ScrollContainer'
import ElementsGroup from '../ElementsGroup'
import Spinner from '../Spinner'
import Button from '../Button'
import SvgEdit from '../SVG/Edit'
import SvgTrash from '../SVG/Trash'
import { getSortedObj } from '../../utilities/getSorted'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import SvgArrowDownLight from '../SVG/ArrowDownLight'

const ListItem = memo(({
    className = "",
    variant = "",
    id,
    title,
    isSelected,
    buttons,
    children,
    droppableId,
    parentCallback
}) => {

    let isElement = React.isValidElement(children)

    className = cl(
        className,
        {
            "List__item": [
                { "text": !isElement }
            ]
        },
        { "selected": isSelected },
        { "selectable-item": variant !== "none" },
        { "not-decorate": variant === "none" },
    )

    return (
        <div className={className} key={id} data-list-item-id={id}>
            {isElement
                ? children
                : <span className="selectable-item__color">
                    {title}
                </span>
            }
            {buttons
                && getButtons()
            }
        </div>
    )

    function getButtons() {
        const {
            edit,
            remove,
        } = buttons

        if (edit || remove) {
            return (
                <ElementsGroup className="List__item-buttons" position="right" offset="mr">
                    {(edit.on && edit.mobile)
                        && <Button className="button-edit" type="string">
                            <SvgEdit size="sm1" variant="dark" />
                        </Button>
                    }
                    {(remove.on && remove.mobile)
                        && <Button className="button-remove" type="string">
                            <SvgTrash size="sm1" variant="dark" />
                        </Button>
                    }
                </ElementsGroup>
            )
        } else {
            return null
        }
    }
    // }
})

const memoGetSortedObj = carryMemoize(getSortedObj, 10)

const ListNew = ({
    className = "",
    name,
    type,
    scroll = {
        on: true,
    },
    label,
    header,
    content,
    selected = [],
    loading = false,
    placeholder = "Элементы пока что отсутствуют.",
    onClick,
    buttons = {},
    sort = {},
    empty,
    droppableId,
    dnd_disabled = false,
    loadButton,
    offset = 0,
    offsetAdd = 20,
    loadMore: loadMoreInner
}) => {





    const [hasMore, setHasMore] = useState(true); // Есть ли ещё данные для подгрузки?
    const [end, setEnd] = useState(false); // Конец, если разница меньше 50
    const [items, setItems] = useState([])
    const newItemsList = getSortedObj(content?.items || [], sort.by, sort.type, sort.order);

    useEffect(() => {

        const newItems = getSortedObj(content?.items || [], sort.by, sort.type, sort.order);
        const lastItem = items[items.length - 1];
        const lastNewContent = newItems[newItems.length - 1];

        if (lastItem?.id === lastNewContent?.id) {
            return
        }
        setHasMore(false);
        if (((newItems.length || 0) - (items.length || 0)) < 50) {
            setEnd(true)
        } else {
            setEnd(false)

        }
        setItems(newItems)
    }, [items, content])





    const headerSizes = {
        pb: header?.size || "md",
        elementsOffset: (header?.size && header?.size === "sm") ? "mr" : "sm1"
    }

    className = cl(
        className,
        {
            "List": [
                type,
                { "cursor-pointer": typeof onClick === "function" }
            ]
        }
    )

    const headerClassName = cl(
        {
            "List__header": [
                {
                    "elements": () =>
                        (header && !React.isValidElement(header.element) && (header.left || header.right))
                            ? true
                            : false
                },
                `pb-${headerSizes.pb}`
            ]
        }
    )

    const contentClassName = cl(
        {
            "List__content": [
                { "white": () => (type === "simple" && scroll.on !== true) ? true : false }
            ]
        }
    )

    const scrollVariant = cl(
        { "white": () => (type === "simple" && scroll.on === true) ? true : false },
        { "none": () => (type === "destructed" && scroll.on === true) ? true : false },
    )


    const loadMore = () => {
        if (typeof loadMoreInner === 'function') {
            setHasMore(true)
            loadMoreInner()
        }

    }

    return (
        <section className={className}>
            {(header || label)
                && <div className={headerClassName}>
                    {React.isValidElement(header?.element)
                        ? header?.element
                        : <>
                            <ElementsGroup position="left" offset={headerSizes.elementsOffset}>
                                {label
                                    && <div className="List__label">
                                        {label}
                                    </div>
                                }
                                {header?.left}
                            </ElementsGroup>
                            <ElementsGroup position="right" offset={headerSizes.elementsOffset}>
                                {header?.right}
                            </ElementsGroup>
                        </>
                    }
                </div>
            }

            <div className={contentClassName} onClick={hClick}>
                {scroll.on
                    ? <ScrollContainer
                        droppableId={droppableId}
                        className={scroll.data?.className}
                        size={scroll.data?.size || "lg"}
                        collapsed={scroll.data?.collapsed !== empty ? scroll.data?.collapsed : true}
                        maxHeight={scroll.data?.maxHeight || empty}
                        variant={scrollVariant}
                    >
                        {/* <DragDropContext onDragEnd={ handleOnDragEnd }> */}
                        <Droppable droppableId={`${droppableId}`} >
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {getContent()}
                                    {provided.placeholder}
                                </div>
                            )}

                        </Droppable>
                        {/* </DragDropContext> */}
                    </ScrollContainer>
                    : getContent()
                }
            </div>
        </section>
    )

    function getContent() {
        if (!content) return null

        // Загрузка
        if (loading === true) {
            return (
                <Spinner className="pt-xs pb-xs" />
            )
        }
        // Контент
        if (items.length) {
            return (
                <InfiniteScroll
                    dataLength={items.length} //This is important field to render the next data
                    // next={() => getMoreData()}
                    hasMore={hasMore}
                    loader={<Spinner className="pt-xs pb-xs" />}
                    endMessage={
                        end ?
                            (<p style={{ textAlign: 'center' }} >
                                <b>Конец списка</b>
                            </p>) :
                            (loadButton || (<div className="ScrollContainer__more">
                                <div onClick={loadMore}>
                                    Загрузить ещё
                                    <SvgArrowDownLight title="Загрузить ещё" variant="dark" size="xs" />
                                </div>
                            </div>))
                    }
                    // scrollThreshold="0.7"
                    scrollableTarget={`scrollableDiv${droppableId}`}
                >
                    {newItemsList.map((item, index) =>
                        <Draggable key={`${item.id}_${index}`} draggableId={`${item.id}-${droppableId}`} index={index} isDragDisabled={dnd_disabled}>
                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <ListItem

                                    className={`${content.className || ""} ${item.className || ""}`}
                                    variant={content.variant}
                                    id={item.id}
                                    key={`${item.id}_${index}`}
                                    title={item.title}
                                    isSelected={item.selected !== empty ? item.selected : (selected.indexOf(item.id) !== -1)}
                                    buttons={buttons}
                                    droppableId={droppableId}
                                >
                                    {item.element}
                                </ListItem>
                            </div>)}
                        </Draggable>
                    )
                    }
                </InfiniteScroll>
            )


            // Пусто
        } else {
            return (
                <div className="List__empty">
                    {placeholder}
                </div>
            )
        }
    }

    function hClick(event) {
        // parentCallback(items)
        const target = event.target
        const item = target.closest(".List__item")
        const edit = target.closest(".button-edit")
        const remove = target.closest(".button-remove")

        if (item) {
            const id = Number(item.dataset.listItemId)
            if (edit) {
                if (isFunc(buttons?.edit?.onClick)) {
                    buttons.edit.onClick({ name, id }, event)
                }
            }
            else if (remove) {
                if (isFunc(buttons?.remove?.onClick)) {
                    buttons.remove.onClick({ name, id }, event)
                }
            }
            else {
                if (isFunc(onClick)) {
                    onClick({ name, id, items }, event)
                }
            }
        }
    }
}

export default ListNew