import { LOADING, LOAD_RUBRICS, PUT_PUBLIC_FILTER, PUT_PUBLIC_FILTER_LOADING, PUT_PUBLIC_FILTER_PARAMS, PUT_RUBRICS, PUT_RUBRICS_ID, UPDATE_FILTER_PARAMS } from './actions'

const initialState = {
    loading: false,
    loading_all: false,
    rubrics: "",
    playlist: "",
    public_filter: {
        
    },
    public_filter_params: {
        
    },
    public_filter_params_initial: {
        
    },
    filter_params: {

    },
    filter_params_initial: {

    }
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case LOAD_RUBRICS:
            return {
                ...state
            }
        case PUT_RUBRICS:
            return {
                ...state,
                rubrics: payload.rubrics,
                playlist: payload.playlist,
                filter_params: payload.filter_params,
                filter_params_initial: payload.filter_params_initial
            }
        case PUT_RUBRICS_ID:
            return {
                ...state,
                playlist: payload
            }
        case PUT_PUBLIC_FILTER:
            return {
                ...state,
                ...payload,
                loading_all: false
            }
        case PUT_PUBLIC_FILTER_LOADING:
            return {
                ...state,
                loading_all: payload
            }
        case UPDATE_FILTER_PARAMS:
            return {
                ...state,
                filter_params: payload
            }
        case UPDATE_FILTER_PARAMS:
            return {
                ...state,
                filter_params: payload
            }
        case PUT_PUBLIC_FILTER_PARAMS:
            return {
                ...state,
                public_filter_params: payload
            }

    }
    return state
}

export default reducer