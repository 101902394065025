import React, { useRef, useState } from "react";

const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");

  const formatTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes}:${seconds >= 10 ? seconds : "0" + seconds}`;
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    const current = audio.currentTime;
    const duration = audio.duration || 0;
    setProgress((current / duration) * 100);
    setCurrentTime(formatTime(current));
  };

  const handleProgressBarClick = (e) => {
    const audio = audioRef.current;
    const progressBar = e.target.getBoundingClientRect();
    const clickPosition = e.clientX - progressBar.left;
    const newTime = (clickPosition / progressBar.width) * audio.duration;
    audio.currentTime = newTime;
  };

  return (
    <div id="player" style={styles.player}>
      <button
        onClick={handlePlayPause}
        style={styles.control}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        <i
          className={`far ${
            isPlaying ? "fa-pause-circle" : "fa-play-circle"
          } fa-2x`} ые
        ></i>
      </button>
      <div
        id="progress-bar"
        style={styles.progressBar}
        onClick={handleProgressBarClick}
      >
        <span
          id="progress"
          style={{ ...styles.progress, width: `${progress}%` }}
        ></span>
      </div>
      <div id="timer" style={styles.timer}>
        {currentTime}
      </div>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
      />
    </div>
  );
};

const styles = {
  player: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    padding: "10px",
    width: "470px", // Фиксированная ширина
    backgroundColor: 'rgba(249, 249, 249, 0.23)',
    border: "1px solid #ddd",
    borderRadius: "50px",
    height: '40px',
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  control: {
    border: "none",
    background: "none",
    cursor: "pointer",    
    display: "flex",
    color: "rgba(10, 10, 10, 0.48)",
    justifyContent: "center",
    alignItems: "center",
  },
  progressBar: {
    flex: 1,
    backgroundColor: "rgba(10, 10, 10, 0.3)",
    height: "8px",
    cursor: "pointer",
    borderRadius: "4px",
    position: "relative",
    overflow: "hidden",
  },
  progress: {
    backgroundColor: "#1d2226",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "width 0.1s linear",
  },
  timer: {
    minWidth: "50px",
    textAlign: "center",
    fontSize: "14px",
    color: "#333",
  },
};

export default AudioPlayer;
