import React, { useEffect } from 'react'
import { Button as ButtonBS } from 'react-bootstrap'

import { cl } from '../../../../utilities/cl'
import { mediaTabletAndUp, mediaMobile, isMobile } from '../../../../utilities/media'
import { getNextSelected } from '../../../../utilities/getNextSelected'

import Group from '../Group/Group'

import List from '../../../List'
import Button from '../../../Button'
import Select from '../../../Form/Select'
import InputText from '../../../Form/InputText'


import SvgPlusCircle from '../../../SVG/PlusCircle'
import SvgUpdate from '../../../SVG/Update'
import SvgMoveUp from '../../../SVG/MoveUp'
import SvgMoveDown from '../../../SVG/MoveDown'
import SvgTrash from '../../../SVG/Trash'
import SvgAudio from '../../../SVG/Audio'
import SvgVideo from '../../../SVG/Video'
import SvgArrowUp from '../../../SVG/ArrowUp'
import SvgArrowDown from '../../../SVG/ArrowDown'

import MobileControls from '../../../MobileControls'



const SettingsBackgroundLists = ({
    className = "",
    timelineId,

    used = [],
    usedOrder = {},
    usedOrdered = [],
    usedSelected = [],
    usedVideo,
    usedLoading,
    usedDeleting,

    availPlay = [],
    availVideo = [],
    availPlayAssoc = {},
    availSelected = [],
    availLoading,
    availAddingToUsed,

    savingUsedItemsMoving,

    selectUsedVideo,
    loadUsedList,
    loadAvailList,
    addToUsed,
    deleteUsedItems,
    toggleSelectedItems,
    moveUsedItemTo,
    saveUsedItemsMoving,
}) => {

    className = cl(
        className,
        "SettingsBackgroundLists",
    )

    const [searchName, setSearchName] = React.useState("");

    useEffect(() => {
        loadUsedList({ timelineId })
    }, [timelineId])

    useEffect(() => {
        loadAvailList()
    }, [])

    const hToggleSelected = ({ name, id, items }, event) => {
        let selected = (name === "avail") ? availSelected : usedSelected

        selected = getNextSelected({
            multiple: true,
            itemId: id,
            selected,
            ctrlKey: mediaMobile(true, (event.ctrlKey || event.metaKey)),
            shiftKey: event.shiftKey,
            items,
        })

        toggleSelectedItems({
            stateKey: name,
            selected,
        })
    }

    const hAddToUsed = () => addToUsed({ timelineId })
    const hDeleteUsedItems = () => deleteUsedItems({ timelineId })

    const hMoveUp = () => {
        moveUsedItemTo({
            timelineId,
            usedId: usedSelected[0],
            to: "up"
        })
        timeoutSaveMoving(5000)
    }

    const hMoveDown = () => {
        moveUsedItemTo({
            timelineId,
            usedId: usedSelected[0],
            to: "down"
        })
        timeoutSaveMoving(5000)
    }

    const hSaveUsedItemsMoving = () => saveUsedItemsMoving({
        timelineId
    })

    //
    // render
    //

    const buttonsProps = {
        save: {
            onClick: hSaveUsedItemsMoving,
            disabled: savingUsedItemsMoving || !(usedOrdered.length),
        },
        moveUp: {
            onClick: hMoveUp,
            disabled: !(usedSelected.length === 1 && usedOrder[usedSelected[0]] > 1),
        },
        moveDown: {
            onClick: hMoveDown,
            disabled: !(usedSelected.length === 1 && usedOrder[usedSelected[0]] < used.length),
        },
        remove: {
            onClick: hDeleteUsedItems,
            disabled: usedDeleting || !(usedSelected.length),
        },
        add: {
            onClick: hAddToUsed,
            disabled: availAddingToUsed || !(typeof timelineId === "number" && availSelected.length),
        },
    }

    return (
        <>
            <div className='my-4'>
                <InputText
                    attrs={{
                        name: "PlayListSearch",
                        placeholder: "Поиск по названию плейлиста",
                        value: searchName
                    }}
                    onChange={(e) => setSearchName(e.value)}
                />
            </div>
            <Group className={className}>
                <div>
                    {mediaMobile(
                        <>
                            <Select
                                type="options"
                                name={timelineId}
                                label="Привязанное видео"
                                placeholder="Выберите список"
                                disabled={!(typeof timelineId === "number")}
                                selected={usedVideo}
                                options={availVideo}
                                onChange={selectUsedVideo}
                            />
                            <div className="pt-md1"></div>
                        </>,
                        null
                    )}



                    <div className="SettingsBackgroundLists__wrap">

                        <List
                            type="simple"
                            name={timelineId}
                            label={mediaMobile("Использует", "Используемые списки")}
                            header={mediaTabletAndUp({
                                size: "sm",
                                right: <>
                                    <Button
                                        type="string"
                                        onClick={() => buttonsProps.moveUp.onClick() && buttonsProps.save.onClick()}
                                        disabled={buttonsProps.moveUp.disabled}
                                    >
                                        <SvgMoveUp title="Переместить вверх" size="sm1" variant="dark" />
                                    </Button>
                                    <Button
                                        type="string"
                                        onClick={() => buttonsProps.moveDown.onClick() && buttonsProps.save.onClick()}
                                        disabled={buttonsProps.moveDown.disabled}
                                    >
                                        <SvgMoveDown title="Переместить вниз" size="sm1" variant="dark" />
                                    </Button>
                                    <Button
                                        type="string"
                                        onClick={buttonsProps.remove.onClick}
                                        disabled={buttonsProps.remove.disabled}
                                    >
                                        <SvgTrash title="Убрать выделенные списки" size="sm1" variant="dark" />
                                    </Button>
                                </>,
                            })}
                            content={{
                                items: getListUsed()
                            }}
                            scroll={{ on: !isMobile() }}
                            selected={usedSelected}
                            onClick={hToggleSelected}
                            loading={usedLoading}
                        />

                        <List
                            type="simple"
                            name="avail"
                            label={mediaMobile("Доступные", "Доступные списки")}
                            header={mediaTabletAndUp({
                                size: "sm",
                                right: <>
                                    <Button
                                        type="string"
                                        onClick={buttonsProps.add.onClick}
                                        disabled={buttonsProps.add.disabled}
                                    >
                                        <SvgPlusCircle size="sm1" variant="dark" />
                                    </Button>
                                </>,
                            })}
                            content={{
                                items: getListAvailPlay()
                            }}
                            scroll={{ on: !isMobile() }}
                            selected={availSelected}
                            onClick={hToggleSelected}
                            loading={availLoading}
                        />
                    </div>

                    {mediaTabletAndUp(
                        <>
                            <div className="pt-md1"></div>

                            <Select
                                type="options"
                                name={timelineId}
                                label="Привязанное видео"
                                placeholder="Выберите список"
                                disabled={!(typeof timelineId === "number")}
                                selected={usedVideo}
                                options={availVideo}
                                onChange={selectUsedVideo}
                            />
                        </>,
                        null
                    )}
                </div>
            </Group>

            <MobileControls active={usedSelected.length > 0 || availSelected.length > 0}>
                <div className="SettingsBackgroundLists__mobileControls">
                    <ButtonBS className="remove" onClick={buttonsProps.remove.onClick} disabled={buttonsProps.remove.disabled} variant="light">
                        <SvgTrash className="mr-mr" /> Удалить
                    </ButtonBS>
                    <ButtonBS className="save" onClick={buttonsProps.save.onClick} disabled={buttonsProps.save.disabled} variant="prim">
                        Сохранить
                    </ButtonBS>
                    <ButtonBS className="add" onClick={buttonsProps.add.onClick} disabled={buttonsProps.add.disabled} variant="light">
                        Добавить
                    </ButtonBS>
                    <ButtonBS className="moveup" onClick={buttonsProps.moveUp.onClick} disabled={buttonsProps.moveUp.disabled} variant="light">
                        <SvgArrowUp />
                    </ButtonBS>
                    <ButtonBS className="movedown" onClick={buttonsProps.moveDown.onClick} disabled={buttonsProps.moveDown.disabled} variant="light">
                        <SvgArrowDown />
                    </ButtonBS>
                </div>
            </MobileControls>
        </>
    )

    function getListUsed() {
        let list = []

        used.map(item => {
            if (availPlayAssoc[item.playlist_id]) {
                if (searchName === '' || searchName !== '' && !!availPlayAssoc[item.playlist_id].name
                    && availPlayAssoc[item.playlist_id].name.toLowerCase()
                    .indexOf(searchName.toLowerCase()) !== -1
                ) {
                    list.push(getItem({
                        id: item.id,
                        name: availPlayAssoc[item.playlist_id].name,
                        type: availPlayAssoc[item.playlist_id].type,
                        totalcount: availPlayAssoc[item.playlist_id].totalcount,
                        totaltime: availPlayAssoc[item.playlist_id].totaltime,
                    }))

                }
            }
        })
        return list
    }

    function getListAvailPlay() {
        let list = []
        availPlay
            .filter(item => searchName !== '' ?
                item["name"].toLowerCase().indexOf(searchName.toLowerCase()) !== -1
                : true
            )
            .map(item =>
                list.push(getItem(item))
            )
        return list;
    }


    function getItem(item) {
        return {
            id: item.id,
            element:
                <div className="SettingsBackgroundLists__item"  title={`(${item.totalcount || 0} эл, ${item.totaltime  || '00:00'} )`}>
                    <div className="icon">
                        <SvgAudio size="sm1" variant="dark-sm" />
                        {/* {(item.type === "audio")
                            && <SvgAudio size="sm1" variant="dark-sm"/>
                        }
                        {(item.type === "video")
                            && <SvgVideo size="sm1" variant="dark-sm"/>
                        } */}
                    </div>
                    <div className="title List__text-fade">
                        {item.name}
                    </div>
                </div>
        }
    }

    function timeoutSaveMoving(time) {
        if (!window.__timeoutSaveMovingCounter) window.__timeoutSaveMovingCounter = 0
        window.__timeoutSaveMovingCounter++

        setTimeout(() => {
            if (window.__timeoutSaveMovingCounter === 1 && usedOrdered.length) {
                hSaveUsedItemsMoving()
            }
            window.__timeoutSaveMovingCounter--
        }, time)
    }
}

export default SettingsBackgroundLists