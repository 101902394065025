import moment from "moment"

const now = moment().add(-1,'day').endOf('day');

export function deleteTimeOutClipsFromPrograms(clips) {

    // clips.forEach(element => {
    //     console.log(element.todate, `\n${moment(element.todate)}`, `\n${now}`,
    //         now.isBefore(moment(element.todate))
    //     );
    // });
    const result = clips.filter(clip => now.isBefore(moment(clip.todate)))
    return result;
}