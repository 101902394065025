import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { saveTrackForItem } from '../../../../../../../store/Settings/actions/sets/Object/Schedule'

import { OBJECT_SCHEDULE } from '../../../../../../../store/Settings/sets'

import ChangeTrack from '../../../components/ChangeTrack'
import Button from '../../../../../../Button'
import SvgDownload from "../../../../../../SVG/Download";
import { download } from '../../../../../../../utilities/download'

import AudioPlayer from './AudioPlayer'


const playerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ddd",
    borderRadius: "5px",
    width: "fit-content",
};

const audioStyles = {
    width: "470px",
    height: "32px",
    outline: "none",
    backgroundColor: '#c4c4c4'
};

const ModalContent = ({
    stateKey,
    side,
    position,

    savingTrackForItem,

    saveTrackForItem,

    download
}) => {

    const [selected, setSelected] = useState()
    const [filename, setFilename] = useState()

    const hChange = ({ value, filename }) => {
        setSelected(value)
        console.log({ value, filename });

        setFilename(filename)
    }

    const hSaveTrackForItem = () => saveTrackForItem({
        stateKey,
        trackId: selected,
        side,
        position,
    })

    const downloadTrack = async () => {

        try {
            download(filename)
        }
        catch (e) {
            console.log('download Track err-> ', e);

        }
    }

    return (
        <>
            <ChangeTrack
                label="Выбрать из списка"
                stateKey={stateKey}
                side={side}
                position={position}

                onChange={hChange}
            />
            <div style={{ height: 70, paddingTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {filename &&
                    <>
                        <AudioPlayer src={filename} />
                        <button
                            onClick={downloadTrack}
                            type="button" // Уточнение: тип кнопки должен быть "button", а не "string".                            
                            style={{
                                boxShadow: '0 4px 4px rgba(89, 89, 128, 0.1)',
                                width: '32px',
                                height: '32px',
                                alignItems: 'center',
                                marginLeft: '24px',
                                justifyContent: 'center',
                                borderRadius: 20,
                                padding: 8,
                                border: 'none', // Убедитесь, что кнопка не имеет дефолтной рамки, которая может перекрывать эффект.
                                background: 'transparent', // Убедитесь, что фон прозрачный или задан.
                            }}
                        >
                            <SvgDownload size={20} />
                        </button>
                    </>
                }
            </div>

            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={hSaveTrackForItem} disabled={savingTrackForItem}>
                    {savingTrackForItem ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => ({
    savingTrackForItem: state.settings.settings[OBJECT_SCHEDULE][props.stateKey]?.__savingTrackForItem
})

const actionsToProps = {
    saveTrackForItem,
    download
}

const ModalContentContainer = connect(stateToProps, actionsToProps)(ModalContent)


const changeTrack = ({
    stateKey,
    side,
    position,

    openModal,
}) => {

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Выбор объявления",
            element:
                <ModalContentContainer
                    stateKey={stateKey}
                    side={side}
                    position={position}
                />
        }
    })
}

export default changeTrack