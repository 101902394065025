import React, { useRef, useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../../utilities/cl'
import { showAlertSuccess, showAlertError } from '../../../../../store/Alert/actions'
import { openModal, closeModal } from '../../../../../store/Modal/actions'
import List from '../../../../../components/DraggableList'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import Song from './Songs'
import Button from '../../../../../components/Button'
import Ok from '../../../../../components/SVG/Ok'
import Trash from '../../../../../components/SVG/Trash'
import AllPlaylists from '../AllPlaylists'
import AllSongs from '../AllSongs'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SongItem from '../AllSongs/Songs'
import { toggleSelectedSong, addSongToPlaylist, sortSongs } from '../../../../../store/ContentPlaylists/actions'
import { toggleSelectedPublicSong } from '../../../../../store/PublicPlaylists/actions'
import { updateCurrentMark } from '../../../../../store/Marks/actions'
import { MEDIA_BREAKPOINT_LG } from '../../../../../utilities/breakpoints'
import ElementsGroup from '../../../../../components/ElementsGroup'
import SvgArrowDownLight from '../../../../../components/SVG/ArrowDownLight'
import { loadAssocPublicTracks } from '../../../../../store/PublicPlaylists/actions'
import InfiniteScroll from 'react-infinite-scroll-component';
import Empty from './Empty'
import { updateFilterParams } from "../../../../../store/Modal/types/PublicSongsFilter/actions";
import { debounce } from '../../../../../utilities/debounce'
import { loadAllPublicSongs } from '../../../../../store/PublicPlaylists/actions'
import AllPublicSongs from './AllSongs'
import ListNew from '../../../../../components/DraggableList/ListNew'


const MusicList = ({
    className = "",
    selectedPlaylists,
    currentPlaylist,
    songs,
    searched_value_ref,
    selected_song,
    selected_song_all,
    selected_public_song,
    placeNumber,
    active,
    toggleSelected,
    toggleSelectedSong,
    toggleSelectedPublicSong,
    updateCurrentMark,
    variant,
    selected_public,
    assoc_public_tracks,
    allPublicTracks,
    loadingAll,
    addSongToPlaylist,
    showAlertSuccess,
    showAlertError,
    openModal,
    closeModal,
    sortSongs,
    selected,
    loadAssocPublicTracks,
    filter_params,
    updateFilterParams,
    loadAllPublicSongs
}) => {
    //////////////////////////////////////////////
    const [sort_direction, setSortDirection] = useState("")
    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    const [offset, setOffset] = useState(0)
    const [firstEl, setFirst] = useState("")

    const allTracksRef = useRef()


    const hasFilter = filter_params.genre_name?.length > 0 || filter_params.songauthor_name?.length > 0 || filter_params.tempo?.length > 0;
    useEffect(() => {
        if (lastClickedPlayId === trackPlaying) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])

    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortSongs({ direction: sort_direction, sort_type: type, id: selected[0] })
    }


    const hPlay = ({ trackId, filename }) => {
        if (trackId === trackPlaying) {
            setLastClickedPlayId(trackId)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }

    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }

    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }
    ///////////////////////////////////////////
    const selectAllS = (e) => {
        let songs_ids = Object.values(currentPlaylist.songs).map(song => {
            return Number(song.id)
        })
        songs_ids.forEach(id => {
            if (selected_song.length === Object.keys(currentPlaylist.songs).length) {
                toggleSelectedSong({
                    id_song: id,
                    onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                })
            } else {
                if (selected_song.indexOf(id) === -1) {
                    toggleSelectedSong({
                        id_song: id,
                        onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                    })
                }
            }
        })
        updateCurrentMark()
    }
    const toggleSelectedS = ({ }, e) => {
        const songTitle = e.target
        document.getSelection().removeAllRanges()

        let id = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
        let i = 0
        let selection_arr = []
        let reverse

        if (e.nativeEvent.shiftKey) {

            e.preventDefault();

            for (let song of Object.values(currentPlaylist.songs)) {
                if (selected_song[0] === song.id) {
                    i = 1
                    continue
                }
                if (i === 1) {
                    if (song.id !== id) {
                        selection_arr.push(song.id)
                    } else {
                        selection_arr.push(song.id)
                        break
                    }
                }
            }
            if (selection_arr.indexOf(id) === -1) {
                reverse = Object.values(currentPlaylist.songs).reverse()
                console.log(reverse[0])
                selection_arr = []
                i = 0
                for (let track of reverse) {
                    if (selected_song[0] === track.id) {
                        i = 1
                        continue
                    }
                    if (i === 1) {
                        if (track.id !== id) {
                            console.log(track.id)
                            selection_arr.push(track.id)
                        } else {
                            selection_arr.push(track.id)
                            break
                        }
                    }
                }
            }
            selection_arr.map(id => {
                toggleSelectedSong({
                    id_song: id,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
            })
            updateCurrentMark()
        }
        if (songTitle && !e.nativeEvent.shiftKey) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                toggleSelectedSong({
                    id_song: songId,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
                updateCurrentMark()
            }
        }
    }
    const selectAllP = (e) => {
        let songs_ids = Object.values(assoc_public_tracks).map(song => {
            return Number(song.id)
        })
        songs_ids.forEach(id => {
            if (selected_public_song.length === Object.keys(assoc_public_tracks).length) {
                toggleSelectedPublicSong({
                    id_song: id,
                    onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                })
            } else {
                if (selected_public_song.indexOf(id) === -1) {
                    toggleSelectedPublicSong({
                        id_song: id,
                        onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                    })
                }
            }
        })
        updateCurrentMark()
    }
    const toggleSelectedP = ({ }, e) => {
        const songTitle = e.target
        document.getSelection().removeAllRanges()

        let id = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
        let i = 0
        let selection_arr = []
        let reverse
        if (e.nativeEvent.shiftKey) {
            console.log({ id, songTitle });


            e.preventDefault();
            console.log('>>', selected.length, [...public_playlist_items.values()].length, [...allPublicTracks.values()].length);

            let list = !!selected_public.length ? [...public_playlist_items.values()] : [...allPublicTracks.values()];
            console.log({ selected, list, l: [...public_playlist_items.values()]  });

            for (let song of list) {

                if (selected_public_song[0] === song.id) {
                    i = 1
                    continue
                }
                if (i === 1) {
                    if (song.id !== id) {
                        selection_arr.push(song.id)
                    } else {
                        selection_arr.push(song.id)
                        break
                    }
                }
            }
            console.log({ selected_public_song });

            if (selection_arr.indexOf(id) === -1) {
                reverse = Object.values(assoc_public_tracks).reverse()
                selection_arr = []
                i = 0
                for (let track of reverse) {
                    if (selected_public_song[0] === track.id) {
                        i = 1
                        continue
                    }
                    if (i === 1) {
                        if (track.id !== id) {
                            console.log(track.id)
                            selection_arr.push(track.id)
                        } else {
                            selection_arr.push(track.id)
                            break
                        }
                    }
                }
            }
            selection_arr.map(id => {
                toggleSelectedPublicSong({
                    id_song: id,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
            })
            updateCurrentMark()
        }
        if (songTitle && !e.nativeEvent.shiftKey) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                toggleSelectedPublicSong({
                    id_song: songId,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
                updateCurrentMark()
            }
        }
    }

    const [playlist_items_ref, setPlaylistItems] = useState([])
    const [public_playlist_items, setPublicPlaylistItems] = useState([])

    const [searched_value, setSearchedValue] = useState('');

    const updateSearch = React.useCallback(debounce((value) => {
        setSearchedValue(value);
    }, 2000), []);


    useEffect
        (searched_value_ref !== '' ?
            () => { updateSearch(searched_value_ref) }
            : () => setSearchedValue(searched_value_ref)
            , [searched_value_ref]
        )

    const playlist_items = playlist_items_ref.filter(item => searched_value ?
        item["name"].toLowerCase().indexOf(searched_value.toLowerCase()) !== -1
        : true
    )

    const playlist_items_all = playlist_items_ref.filter(item => searched_value ?
        item["name"].toLowerCase().indexOf(searched_value.toLowerCase()) !== -1
        : true
    )

    const hSave = () => {
        const collapsible = document.querySelector(".SettingsObjectNetCollapsible")
        const buttonSave = collapsible.querySelector(".SettingsObjectNetSave button")
        if (buttonSave) {
            buttonSave.click()
        }
    }
    className = cl(
        className,
        "Songs_collapsible"
    )
    const getItems = () => {
        // return playlist_items.sort((a, b) => Number(a.turn) - Number(b.turn)).map((song, index) => ({
        return playlist_items.map((song, index) => ({
            //return (
            id: song.id,
            name: song.name,
            element: < Song
                key={song.id}
                id={song.id}
                name={song.name}
                turn={song.turn}
                cliptime={song.cliptime}
                selected={selected_song}
                tempo={song.tempo}
                songauthor_name={song.songauthor_name}
                hide_song_author_name={true}
                player_name={song.player_name}
                album_name={song.album_name}
                genre_name={song.genre_name}
                year={song.year}
                variant={(index % 2 == 0) ? "gray" : ""}
                filename={song.filename}

                audio={audio}
                trackPlaying={trackPlaying}
                isPlaying={isPlaying}
                isWatching={isWatching}
                play={hPlay}
                pause={hPause}
                stop={hStop}
                rewind={hRewind}

            />
        }))
    }
    const songRenderItem = (song, index) => ({

        id: song.id,
        name: song.name,
        element: < SongItem
            key={song.id}
            id={song.id}
            name={song.name}
            variant={(index % 2 == 0) ? "gray" : ""}
            songs={songs}
            selected={selected_public_song}
            tags={song.tags}
            turn={song.turn}
            cliptime={song.cliptime}
            tempo={song.tempo}
            songauthor_name={song.songauthor_name}
            hide_song_author_name={true}

            player_name={song.player_name}
            album_name={song.album_name}
            genre_name={song.genre_name}
            year={song.year}

            filename={song.filename}

            audio={audio}
            trackPlaying={trackPlaying}
            isPlaying={isPlaying}
            play={hPlay}
            pause={hPause}
            stop={hStop}
            rewind={hRewind}
        />
    })
    const getPublicItems = () => {

        // return public_playlist_items.sort((a, b) => Number(a.turn) - Number(b.turn)).map((song, index) => ({
        return public_playlist_items.map((song, index) => songRenderItem(song, index))
    }

    const curSongs = currentPlaylist?.songs || {}
    useEffect(() => {

        if (currentPlaylist && currentPlaylist.songs)
            setPlaylistItems(Object.values(currentPlaylist.songs))
    }, [currentPlaylist, curSongs])


    useEffect(() => {
        setPublicPlaylistItems([...assoc_public_tracks.values()])
    }, [assoc_public_tracks])




    const loadMorePublicTraks = (fromAll) => {
        setOffset(offset + 50)
        if (hasFilter) {
            updateFilterParams({ ...filter_params, offset: offset + 50 })
        }
        else {
            loadAssocPublicTracks({ offset: offset + 50 })
        }
        // console.log("OFFSET", offset)
        console.log(" >> >> ", { offset });
        if (fromAll)
            loadAllPublicSongs({ offset: offset + 50 })
        else
            loadAssocPublicTracks({ offset: offset + 50 })

    }


    let public_playlist_content;
    if (public_playlist_items.length > 0) {
        public_playlist_content = <ListNew
            type="simple"
            // offset={offset}
            offsetAdd={50}
            // loadButton={!hasFilter && <div className="ScrollContainer__more">
            //     <div onClick={() => loadMorePublicTraks()}>
            //         Загрузить ещё
            //         <SvgArrowDownLight title="Загрузить ещё" variant="dark" size="xs" />
            //     </div>
            // </div>}
            loadMore={loadAssocPublicTracks}
            scroll={{
                on: true,
                data: {
                    variant: "white",
                    size: "sm",
                }
            }}
            onClick={toggleSelectedP}
            content={{ items: getPublicItems(), variant: "none" }}
            droppableId={`1`}
        />
    }



    const handleResult = (result) => {

        let { destination, source } = result
        if (!destination) return
        if (destination.droppableId === `1` && source.droppableId === `1`) return
        if (destination.droppableId === source.droppableId) {
            if (source.droppableId === `0`) {

                let playlist_item = playlist_items[source.index]

                playlist_items.splice(source.index, 1)
                playlist_items.splice(destination.index, 0, playlist_item)
                setPlaylistItems([...playlist_items])


            } else if (destination.droppableId === `1`) {
                showAlertError({ text: `Публичный плейлист нельзя изменять.`, ms: 3000 })
                return
            }

        } else {
            if (destination.droppableId !== `1`) {
                let public_playlist_item = public_playlist_items[source.index]
                public_playlist_items.splice(source.index, 1)

                if (!public_playlist_item)
                    public_playlist_item = allTracksRef.current?.getTrackByIndex(source.index)

                playlist_items.splice(destination.index, 0, public_playlist_item)
                setPlaylistItems([...playlist_items])
                addSongToPlaylist({
                    id_playlist: selectedPlaylists[0], selected_song: playlist_items.map(song => {
                        return song.id
                    }), songs: playlist_items
                })
            } else {
                showAlertError({ text: `Публичный плейлист нельзя изменять.`, ms: 3000 })
                return
            }
        }

    }


    const deleteSongs = () => {


        let clear = playlist_items.map(song => {
            return song.id
        }).filter(id => selected_song.indexOf(id) === -1)
        let playlist_items_update = Array.from(playlist_items).filter(song => selected_song.indexOf(song.id) === -1)
        setPlaylistItems([...playlist_items_update])
        addSongToPlaylist({ id_playlist: selectedPlaylists[0], selected_song: clear, songs: playlist_items_update })
    }
    const addSongs = (fromAll) => {
        // const fromAll = !!!selectedPlaylists[0]
        let current = playlist_items.map(song => {
            return song.id
        })


        let full = [...current, ...selected_public_song]
        console.log("123", fromAll, public_playlist_items);

        const newTracks = fromAll ? allTracksRef.current?.getSelectedTracks() : public_playlist_items.filter(song => selected_public_song.indexOf(song.id) !== -1);
        console.log({ fromAll, newTracks });

        let playlist_items_update = [...Array.from(playlist_items), ...newTracks]


        let compair = true
        selected_public_song.map(el => {
            if (current.indexOf(el) !== -1) {
                compair = false
            }
        })
        if (compair === false) {
            showAlertError({ text: `Выбранное видео уже есть в текущем списке`, ms: 3000 })
            return
        }
        setPlaylistItems([...playlist_items_update])
        console.log(";;--;;");

        addSongToPlaylist({ id_playlist: selectedPlaylists[0], selected_song: full, songs: playlist_items_update })
    }
    return (
        <DragDropContext onDragEnd={handleResult}>
            {selectedPlaylists?.length > 0 && <>
                <div style={{ marginTop: -62 }}>
                    <ElementsGroup position="right">
                        <Button
                            type="string"
                            disabled={selected_song.length > 0 ? false : true}
                            onClick={() => openModal({
                                type: "confirm",
                                size: "sm",
                                data: {
                                    title: "Вы точно хотите удалить выделенные треки из этого плейлиста?",
                                    buttons: {
                                        cancel: {
                                            title: "Вернуться",
                                            onClick: () => {
                                                closeModal("confirm")
                                            }
                                        },
                                        confirm: {
                                            title: "Удалить",
                                            onClick: () => {
                                                deleteSongs()
                                                closeModal("confirm")
                                            }
                                        }
                                    }
                                }
                            })
                            }
                        >
                            <Trash /> Удалить из плейлиста {selected_song.length > 0 ? `(${selected_song.length})` : ''}
                        </Button>
                        <Button
                            type="string"
                            onClick={selectAllS}
                            disabled={currentPlaylist && currentPlaylist?.songs && Object.keys(currentPlaylist.songs).length > 0 ? false : true}
                        >
                            <Ok size={`xs`} /> Выделить все строки {currentPlaylist && currentPlaylist?.songs && Object.keys(currentPlaylist.songs).length ? `(${Object.keys(currentPlaylist.songs).length})` : ''}
                        </Button>
                    </ElementsGroup>
                </div>
                <div className="Songs">
                    <header className="Songs__control">
                        <div className="Songs__grid">
                            <div className="id">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("name")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ID</span>
                                </button>
                            </div>
                            <div className="composition">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("name")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    ПРОИЗВЕДЕНИЕ
                                </button>
                            </div>
                            <div className="duration"
                                style={{ marginLeft: 36 }}
                            >
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("duration")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ДЛИТ.</span>
                                </button>
                            </div>
                            <div className="temp">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("temp")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ТЕМП</span>
                                </button>
                            </div>
                            <div className="chill_out">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("genre")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ЖАНР</span>
                                </button>
                            </div>

                            <div className="performer" hidden>
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("player")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ИСПОЛНИТЕЛЬ</span>
                                </button>
                            </div>
                            {/* <div className="album">
                            <button
                                className="spp-btn spp-btn_string"
                                onClick={ () => sort("album") }
                            >
                                <SvgArrowUpDown className="spp-svg" />
                                <span>АЛЬБОМ</span>
                            </button>
                        </div> */}
                            <div className="added">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("added")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    <span>ДОБАВЛЕНО</span>
                                </button>
                            </div>
                        </div>
                    </header>
                    < div onClick={toggleSelected}>
                        <List
                            type="simple"
                            scroll={{
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "sm",
                                }
                            }}
                            onClick={toggleSelectedS}
                            content={{ items: getItems(), variant: "none" }}
                            // droppableId={ `ownPlaylistSongs` }
                            droppableId={`0`}
                        />
                    </div>
                </div></>}
            <div style={{ width: "100%", marginTop: 36 }} className="All_media" >
                <AllPlaylists />
                <AllSongs
                    public_playlist_items={public_playlist_items}
                    public_playlist_content={public_playlist_content}
                    all_public_content={<AllPublicSongs
                        ref={allTracksRef}
                        songRenderItem={songRenderItem}
                        toggleSelectedP={toggleSelectedP}
                        loadMorePublicTraks={loadMorePublicTraks}
                    />}
                    addSongs={addSongs}
                    selectAll={selectAllP}
                    hide_song_author_name={true}

                />
            </div>

        </DragDropContext >

    )
}

const stateToProps = state => ({
    allPublicTracks: state.public_playlists.allPublicTracks,
    selectedPlaylists: [...state.playlists.selected],
    currentPlaylist: state.playlists.allPlaylists.filter(cur => cur.id === state.playlists.selected[0])[0],
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.playlists.selected],
    searched_value_ref: state.search.songs.value,
    selected_song: state.playlists.selected_song,

    selected_song_all: state.playlists.selected_song_all,
    selected_public_song: state.public_playlists.selected_song,

    assoc_public_tracks: state.public_playlists.assocPublicTracks,

    selected_public: state.public_playlists.selected,
    filter_params: state.modalPublicSongsFilter.filter_params,
})
const actionsToProps = {
    toggleSelectedSong,
    updateCurrentMark,
    toggleSelectedPublicSong,
    addSongToPlaylist,
    showAlertSuccess,
    showAlertError,
    openModal,
    closeModal,
    sortSongs,
    loadAssocPublicTracks,
    updateFilterParams,
    loadAllPublicSongs
}

export default connect(stateToProps, actionsToProps)(MusicList)