import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT } from '../../routes'

import Panel from '../../../components/PanelContent'
import Place from '../../../components/PanelContent/Place'

import Search from '../../../components/Search'
import { store } from '../../../connect'
import { loadAllPublicSongs, loadAssocPublicTracks } from '../../../store/PublicPlaylists/actions'
import { debounce } from '../../../utilities/debounce'


const AdvertPanel = ({
    page,
    playlistsSearchState,
    loadAssocPublicTracks,
    loadAllPublicSongs

}) => {
    const disabled = isDisabled(page)

    // const onSongsSearchValueChanged = React.useCallback(
    //     debounce((name) => {
    //         const selected_id = store.getState().public_playlists.selected[0];


    //         loadAssocPublicTracks({ name, offset: 0 });

    //         loadAllPublicSongs({ name, offset: 0 });
    //     }, 700),
    //     []
    // );

    const onSubmit = (name) => {
        const selected_id = store.getState().public_playlists.selected[0];


        loadAssocPublicTracks({ name, offset: 0 });

        loadAllPublicSongs({ name, offset: 0 });
    }

    return (
        <Panel page={page} disabled={disabled}>
            <Place
                title="Что"
                number={1}
                className={`${(playlistsSearchState.modeActive) ? "right-extend" : ""}`}
                disabled={disabled}
                elements={{
                    right: <Search placeholder="Поиск по плейлистам" searchType="playlists" disabled={disabled} />
                }}
            />
            <Place
                title="Где"
                number={2}
                disabled={disabled}
                elements={{
                     right: <Search title='Для поиска нажмите "Enter"' placeholder="Поиск по названию композиции" searchType="songs" disabled={disabled} onSubmit={onSubmit} />
                    
                    
                }}
            />
        </Panel>
    )
}

const stateToProps = state => ({
    playlistsSearchState: state.search["playlists"],
    selectedPlaylists: state.playlists.selected,
    selectedPublicPlaylists: state.public_playlists.selected,
    //searchState: state.search
})

const actionsToProps = {
    loadAssocPublicTracks,
    loadAllPublicSongs
}

export default connect(stateToProps, actionsToProps)(AdvertPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== MAIN_OBJECT && page !== MAIN_ADVERT && page !== MAIN_CONTENT) ? true : false
}