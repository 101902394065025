import moment from 'moment'
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_PUBLIC_FILTER, LOAD_RUBRICS, purPublicFilter, putPublicFilterLoading } from '../../../store/Modal/types/PublicSongsFilter/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_PUBLIC_FILTER, worker)
}




function* worker() {
    yield put(putPublicFilterLoading(true))
    try {
        console.log('"');

        const fetchedTempo = yield call(fetchTempo)
        let fetchedGenre = yield call(fetchGenre)
        console.log('"12');
        if (fetchedTempo.error || fetchedGenre.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_RUBRICS}.`, fetchedGenre],
                text: "Фильтры не были загружены. Попробуйте перезагрузить страницу."
            }))
            // yield put(purPublicFilter(initialParams))

        } else {
            console.log(Object.keys(fetchedGenre), typeof fetchedGenre.success, fetchedGenre);

            try {
                fetchedGenre.success = fetchedGenre.success.reduce((res, el) => {

                    res[el.name] = el.name;
                    return res;

                }, {})
            } catch (e) {
                console.log('fetchGenre', e);

            }

            const tempo = [fetchedTempo.success[0], fetchedTempo.success[fetchedTempo.success.length - 1]]

            yield put(purPublicFilter({
                public_filter: {
                    genre_name: fetchedGenre.success,
                    tempo
                },
                public_filter_params: {
                    genre_name: [],
                    tempo: {
                        from: tempo[0],
                        to: tempo[1]
                    }

                },
                public_filter_params_initial: {
                    genre_name: [],
                    tempo: {
                        from: tempo[0],
                        to: tempo[1]
                    }
                },
                loading_all: false

            }))
        }
    }
    catch (e) {
        console.log('catch');
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_RUBRICS}.`, e],
            text: "Фильтры не были загружены. Попробуйте перезагрузить страницу."
        }))
        // yield put(purPublicFilter(initialParams))
    }

    finally {
        console.log('finaly');

        yield put(putPublicFilterLoading(false))
    }
}

function fetchTempo() {
    return axios.get('/song/tempo/select')
        .then(response => response.data)
}

function fetchGenre() {
    return axios.get('/song/genre/select')
        .then(response => response.data)
}
