import React from 'react'

import { cl } from '../../../utilities/cl'

import SvgAudio from '../../SVG/Audio'
import SvgVideo from '../../SVG/Video'

const VideolistAllItem = ({
    id,
    name,
    totalcount,
    totalsize,
    totaltime,
    songs,
    selected,
    markItems,
    variant
}) => {

    const className = cl(
        // "Playlists__item",
        "selectable-item",
        { "Videolist": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )
    // let songs_arr = Object.values(songs)
    // let songs_size = 0
    // let songs_duration = ""
    // let songs_hour = 0
    // let songs_minutes = 0
    // let songs_seconds = 0
    // let hours = 0
    // let minutes = 0
    // let songs_count = songs_arr.length
    // songs_arr.map(song => songs_size += song.size)
    // songs_size = `${Math.round(songs_size / 1024 / 1024)} Mb`
    // songs_arr.map(song => {
    //     let duration_arr = song.cliptime.split(".")[0].split(":")
    //     songs_hour += Number(duration_arr[0])
    //     songs_minutes += Number(duration_arr[1])
    //     songs_seconds += Number(duration_arr[2])
    // })
    // if (songs_seconds % 60 >= 0) {
    //     minutes = Math.floor(songs_seconds / 60)
    //     songs_minutes += minutes
    //     songs_seconds = songs_seconds % 60
    // }
    // if (songs_minutes % 60 >= 0) {
    //     hours = Math.floor(songs_minutes / 60)
    //     songs_hour += hours
    //     songs_minutes = songs_minutes % 60
    // }
    // songs_duration = `${songs_hour}:${songs_minutes}:${songs_seconds}`
    return (
        <div className={ className } data-videolist-id={ id }>
            <div className="Videolist__header">
                <div className="Videolists__grid">
                    <div className="play">
                        <span>
                            <SvgVideo variant="dark-lg" size="sm1" title="Воспроизвести" />
                        </span>
                    </div>
                    <div className="name">
                        {/* { name.length > 25 ? `${name.slice(0, 25)}…` :  } */}
                        <h4>{name}</h4>
                        
                        
                    </div>
                    <div className="tracks_count">{ totalcount }</div>
                    <div className="size">
                        { totalsize }
                    </div>
                    <div className="duration">
                        { totaltime }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideolistAllItem