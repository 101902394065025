import React from 'react'

import { cl } from '../../../utilities/cl'

import SvgAudio from '../../SVG/Audio'
import SvgVideo from '../../SVG/Video'
import SvgPaperclip from '../../SVG/Paperclip'


const VideolistItem = ({
    id,
    name,
    totaltime,
    totalsize,
    totalcount,
    selected,
    loadVideolist,
    markItems,
    variant,
    used
}) => {

    const className = cl(
        // "Playlists__item",
        "selectable-item",
        { "Videolist": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )

    const hClick = (id) => {
        //loadVideolist(id);
    }

    const usedIds = !!used ?
        used
            .map(element => element?.id || null)
            .join(', ') : null;

    return (
        <div className={className} data-videolist-id={id} onClick={(e) => hClick(id)} title={name || ''}>
            <div className="Videolist__header">
                <div className="Videolists__grid">
                    <div className="play">
                        <span>
                            <SvgVideo variant="dark-lg" size="sm1" title="Воспроизвести" />
                        </span>
                    </div>


                    <div className="name">

                        <h4> {usedIds && <SvgPaperclip size="sm1" title={usedIds} />} 
                        {/* {name.length > 35 ? `${name.slice(0, 35)}…` : name} */}
                        {name}
                         </h4>
                    </div>
                    <div className="tracks_count">{totalcount}</div>
                    <div className="size">
                        {totalsize}
                    </div>
                    <div className="duration">
                        {totaltime}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideolistItem